import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRadioButton } from '../../../components/ui';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
import { usaCoverageEnum } from '../../../config/enums';

export function UsaCoverageInclusion(props) {
	const { t } = useTranslation();
	const [usaCoverage, setUsaCoverage] = useState(LocalStorageService.get(localStorageKeys.usaCoverage))
	const handleChangeUsaCoverage = (e) => {
		setUsaCoverage(e.target.value)
		MixpanelService.getInstance().selectUsaCoverage({ usaCoverage: e.target.value === usaCoverageEnum.True ? true : false })
		setTimeout(() => {
			props.handleClickNext()
			LocalStorageService.set(localStorageKeys.usaCoverage, e.target.value);
		}, 500);
	}

	return (
		<div>
			<div className="heading mt-45">
				<p>{props.title ? props.title : t("can_you_tell_me")}</p>
				<h3>{t("usa_coverage_label")}</h3>
			</div>
			<div className="row">
				<div className="col-12 col-md">
					<CustomRadioButton label={t("true")}
						id="True"
						value={usaCoverageEnum.True}
						name="usaCoverage"
						onChange={handleChangeUsaCoverage}
						onClick={handleChangeUsaCoverage}
						checked={usaCoverage === usaCoverageEnum.True || usaCoverage === "true"}
					/>
				</div>
				<div className="col-12 col-md">
					<CustomRadioButton label={t("false")}
						id="False"
						value={usaCoverageEnum.False}
						name="usaCoverage"
						onChange={handleChangeUsaCoverage}
						onClick={handleChangeUsaCoverage}
						checked={usaCoverage === usaCoverageEnum.False || usaCoverage === "false"} />
				</div>
			</div>
		</div>
	)
}
