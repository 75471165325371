import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Button, Select, Spinner } from '../../../components/ui';
import { LocalStorageService, localStorageKeys, MixpanelService } from '../../../application/services';
import { ServiceProxy } from '../../../ServiceProxy';
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export function CarYearField(props) {
    const { t } = useTranslation();
    const [motorYears, setMotorYears] = useState([])
    const [loading, setLoading] = useState(true)
    const [year, setYear] = useState(LocalStorageService.get(localStorageKeys.motorYear) || '')
    const isQuestionExplanation = useFeatureIsOn("question-explanation")
    const handleChangeYear = (e) => {
        setYear(e.target.value)
        MixpanelService.getInstance().selectCarManufactureYear({ year: e.target.value })

    }
    const handleClickNext = () => {
        props.handleClickNext()
        LocalStorageService.set(localStorageKeys.motorYear, year);
        MixpanelService.getInstance().carManufactureYearFieldClickNext({ year })

    }
    useEffect(() => {
        ServiceProxy.UtilityService.getMotorYears()
            .then(response => {
                setLoading(false)
                if (response.data && response.data.model_years.length > 0) {
                    setMotorYears(response.data.model_years)

                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div>
            {loading ? <Spinner /> :
                <>
                    <div className="heading mt-45 mb-30">
                        <p>{t("can_you_tell_me")} </p>
                        <h3>{t("car_year")}</h3>
                        {isQuestionExplanation && <h4>{t("manufacturing_year_explanation")}</h4>}
                    </div>
                    <Select
                        name="year"
                        value={year}
                        placeholder={t("car_year_placeholder")}
                        options={motorYears}
                        idKey={false}
                        onChange={handleChangeYear}
                        onOpen={() => MixpanelService.getInstance().clickManufactureYearFiled()}
                    />
                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!year ? "disabled" : "active"}`}
                            title={t("next")}
                            disabled={!year}
                            type="submit"
                            onClick={handleClickNext}
                        />
                    </div>
                </>
            }
        </div>
    )
}
