import React from "react"
import { TextField } from '@mui/material';
import { etisalatHost } from "../../../config/enums";

export function TextFieldUI({
	placeholder,
	value,
	onChange,
	error,
	helperText,
	name,
	id,
	variant,
	...props
}) {
	return (
		<TextField id={id}
			fullWidth
			placeholder={placeholder}
			value={value}
			variant={variant || etisalatHost ? 'outlined' : 'standard'}
			onChange={onChange}
			error={error}
			helperText={helperText}
			name={name}
			{...props}
		/>
	)
}