import React from 'react'
import MSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import ExpandMore from '../../../assets/img/arrow_down.svg'
import ExpandMoreBlack from '../../../assets/img/arrow_down_black.svg'
import { etisalatHost } from '../../../config/enums';
const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#aaa"
    }
}));
const Placeholder = React.forwardRef(({ children }, ref) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder} ref={ref}>{children}</div>;
});
export function UISelect({
    name,
    label,
    options,
    idKey,
    value,
    placeholder,
    ...props
}) {
    const nodeRef = React.useRef(null)
    return (
        <div className="form-group ui-select" noderef={nodeRef}>
            <MSelect
                {...props}
                labelId={`${name}-label`}
                id={name}
                name={name}
                variant={etisalatHost ? 'outlined' : 'standard'}
                fullWidth
                displayEmpty
                onChange={props.onChange}
                renderValue={
                    value !== "" ? undefined : () => <Placeholder >{placeholder}</Placeholder>
                }
                IconComponent={(props) => (<img src={etisalatHost ? ExpandMoreBlack : ExpandMore} alt="expand" {...props} />)}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 250,
                            width: '50%',
                        },
                    }
                }}
                value={value}
            >
                {props.children
                    ? props.children : options?.map((option, index) => (
                        <MenuItem key={index}
                            value={option}>
                            {idKey ? option.value : label ? option.label : option}
                        </MenuItem>
                    ))}

            </MSelect>
        </div>
    )
}
