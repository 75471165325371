import React from 'react'
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
export function MobileWalletPhoneNumber({ formik }) {
    const { t } = useTranslation();
    return (
        <div className="payment-wrapper">
            <h3>{t("mobile_wallet_number_title")} </h3>
            <div className="mt-30">
                <form onSubmit={formik.handleSubmit} autoComplete="off" >
                    <TextField id="phoneNumber"
                        fullWidth
                        placeholder={t("phone_placeholder")}
                        name="phoneNumber"
                        type="tel"
                        value={formik.values.phoneNumber}
                        variant='standard'
                        onChange={ formik.handleChange}
                        error={
                            formik.touched.phoneNumber &&
                            Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                            formik.touched.phoneNumber &&
                            formik.errors.phoneNumber
                        }
                    />
                </form>
            </div>
        </div>
    )
}
