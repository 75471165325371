import axios from "axios";
export class InsuranceService {

    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/insurance/v1/qe`
    }
    getPolicies(values) {
        return axios.post(`${this._baseUrl}/policy-types/types`, values);
    }
    loadComparison(params) {
        return axios.get(`${this._baseUrl}/policies/comparison`, { params });
    }
    getObjectives() {
        return axios.get(`${this._baseUrl}/util/objectives`);
    }
    loadAll() {
        return axios.get(`${process.env.REACT_APP_API_URL}/quotation/pv1/helper/choices`);
    }
    getSmeMedicalAges() {
        return axios.get(`${this._baseUrl}/policies/corp-medical-ages`);
    }
}
