import React from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Error } from '../../../components'

export const UIModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("modal_error_message_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error message={props.message ? props.message : t("modal_error_message_body")}
          btnText={t("try_again_text")} onClick={() => window.location.reload()} />
      </Modal.Body>
    </Modal>
  )
}
export const InfoModal = (props) => {

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      className="mongez"
    >
      <Modal.Header >
        <Modal.Title>رسالة اخبارية</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          عزيزى عميل منجز، من دلوقتى و لحد ١٠ أكتوبر استمتع مع امنلى بعروض و خصومات تصل الى ١٠٠٠جم والتقسيط على ٦ شهور؛ <br />
          بدون فوائد
          <br />
          بدون مقدم
          <br />
          بدون مصاريف ادارية
          <br />
          للتعرف عل نسبة خصمك اختار البوليصة اللى تناسبك و ادخل بياناتك و هيتم التواصل معاك عن طريق ممثل المبيعات
        </p>
      </Modal.Body>
      <Modal.Footer>

        <button onClick={props.handleClose} className="amenliBtn" >
          اغلاق
        </button>
      </Modal.Footer>
    </Modal>
  )
}
export const Popup = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onClick}
      centered
      className='modalPopup'
    >
      <Modal.Header closeButton />
      <Modal.Body >
        <div className="popup ">
          <h4>{props.title}</h4>
          <p className={props.descStyle}>{props.description}</p>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export const PopupCartona = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onClick}
      centered
      className='popupCartona'
    >
      <Modal.Header closeButton />
      <Modal.Body >
        <div className="popup">
          <h4>{props.title}</h4>
          <p>{props.description}</p>
          {props.btnText && <button onClick={props.handleClose}>{props.btnText}</button>}
        </div>
      </Modal.Body>
    </Modal>
  )
}