import React, { useEffect, useState } from 'react';
import { RadioButton, Spinner } from '../../../components';
import { LocalStorageService, localStorageKeys } from '../../../application/services';
import { etisalatHost } from '../../../config/enums';
import Avatar from '../../../assets/img/avatar.svg';
import { t } from 'i18next';
import { policiesEnum } from '../insuranceType';
import * as Sentry from '@sentry/react';
import { ServiceProxy } from '../../../ServiceProxy';
import { splitParams } from '../../../helpers';
export const BusinessType = (props) => {
	const [policies, setPolicies] = useState([])
	const [loading, setLoading] = useState(true)
	const [id, setId] = useState(LocalStorageService.get(localStorageKeys.insuranceTypeID) || null)
	const handleClickCard = (id, value) => {
		setId(id)
		setTimeout(() => {
			handleClickNext(id, value)
		}, 500);
	}
	const handleClickNext = (id, value) => {
		LocalStorageService.set(localStorageKeys.insuranceTypeID, id);
		LocalStorageService.set(localStorageKeys.insuranceType, value);
		props.history.push({
			pathname: `/${value}/step-one`,
			state: { insurance_type: value },
			search: props.location.search
		})
	}
	const getPolicies = () => {
		const paramsDict = splitParams(window.location.search)
		let promo = paramsDict["prm"];
		if (!promo) {
			promo = LocalStorageService.get(localStorageKeys.promoCode)
		}
		ServiceProxy.InsuranceService.getPolicies({ promo_code: promo })
			.then(response => {
				if (response.data) {
					setPolicies(response.data)
					setLoading(false)
				}
			})
			.catch(function (error) {
				setLoading(false)
				Sentry.captureException(error);
			});
	}
	useEffect(() => {
		getPolicies()
	}, [])// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="container" id="page-wrapper">
			{
				loading ? <Spinner /> :
					<div className="insurance-type-step pl-15 pr-15 home-wrapper">
						{!etisalatHost && <div className="info">
							<div className="user-img">
								<img src={Avatar} alt="Amenli" />
							</div>
							<div className="greeting">
								<span>{t("ahlan_message")}</span>
								<br />
								<p>{t("start_message")}</p>
							</div>
						</div>}
						<div className="heading mt-45 mb-lg-4">
							<p>{t("so")}</p>
							<h3>{t("ask_insurance_interested")}</h3>
						</div>
						<div className="insurance-card-wrapper">
							{policies.filter(item => item.groups.length !== 0).map((type, index) => {
								return (
									<RadioButton
										key={index}
										label={type.display_name}
										name="business_type"
										value={type.policy_type}
										id={type.policy_type}
										checked={id === type.policy_type}
										onClick={() => handleClickCard(type.policy_type, policiesEnum[type.policy_type])}
									/>
								)
							})}
						</div>
					</div>
			}
		</div>
	)
}