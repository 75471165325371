import {Cookies} from "react-cookie";

export const cookieStorageKeys={
    token:'paymnet_token',
}
const cookies = new Cookies();
export class CookieStorageService {
    
    static setCookie = (key, value) => {
        let dateNow = new Date();
        dateNow.setMinutes(dateNow.getMinutes() + 60);
        cookies.set(key, value, { path: "/", expires: dateNow  });
    };
    static getCookie = (key) => {
        return cookies.get(key) ||null
    };
}