
import React from 'react'
import { useTranslation } from "react-i18next";
import Royal from '../../../assets/img/Royal.png';
import Wataniya from '../../../assets/img/Wataniya.png';
import Axa from '../../../assets/img/Axa.png';
import Suez_Canal from '../../../assets/img/Suez-Canal.svg';
import Arope from '../../../assets/img/Arope.png';
import Orient from '../../../assets/img/Orient.png';
import Sarwa from '../../../assets/img/Sarwa.jpg';
import Chubb from '../../../assets/img/Chubb.png';
import GIG from '../../../assets/img/GIG.png'



import { MixpanelService } from '../../../application/services';

export const InsuranceProviders = ({ type }) => {
    const { t } = useTranslation();
    const providers = {
        motor: [{ logo: Orient, name: 'Orient' }, { logo: Axa, name: 'Axa' }, { logo: Arope, name: 'Arope' }, { logo: Sarwa, name: 'Sarwa' }, { logo: Wataniya, name: 'Wataniya' }, { logo: GIG, name: 'GIG' }],
        medical: [{ logo: Royal, name: 'Royal' }, { logo: Axa, name: 'Axa' }, { logo: Orient, name: 'Orient' }, { logo: Arope, name: 'Arope' }],
        travel: [],
        home: [{ logo: Axa, name: 'Axa' }],
        groupMedical: [{ logo: Axa, name: 'Axa' }, { logo: Sarwa, name: 'Sarwa' }, { logo: Chubb, name: 'Chubb' }],
        internationalMedical: [{ logo: Axa, name: 'Axa' }],
    }
    const clickInsuranceCard = (item) => {
        MixpanelService.getInstance().clickInsuranceCompany({ insurance_company_name: item.name, step: window.location.pathname })
    }
    return (
        <div className="parteners-section">
            <div>
                <p>{providers[type].length === 1 ? t('insurance_partener_title') : t('insurance_parteners_title')}</p>
            </div>
            <div className={providers[type].length === 1 || providers[type].length === 2 ? 'cards space' : 'cards'}>
                {providers[type].map((item, index) => (
                    <div className='card' key={index} onClick={() => clickInsuranceCard(item)}>
                        <img src={item.logo} alt="cardIcon" style={{ objectFit: item.logo === Suez_Canal ? 'cover' : 'contain' }} />
                    </div>
                ))}
            </div>
        </div>
    )
}

