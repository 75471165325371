import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Button, TextField } from '../../../components/ui';
import { employeesNumberValidationSchema } from '../schema';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
export function EmployeesNumberField(props) {

    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            employeesNumber: LocalStorageService.get(localStorageKeys.employeesNumber) || '',
        },
        validationSchema: employeesNumberValidationSchema,
        onSubmit: (values) => {
            LocalStorageService.set(localStorageKeys.employeesNumber, values.employeesNumber);
            props.handleClickNext()
            MixpanelService.getInstance().totalEmployeeFieldClickNext({ total_employee: values.employeesNumber })
        },
    });
    return (
        <div>
            <div className="heading mt-45">
                <p>{props.title ? props.title : t("can_you_tell_me")}</p>
                <h3>{t("ask_for_num_of_employees")}</h3>
            </div>
            <div className="mt-30">
                <form onSubmit={formik.handleSubmit} autoComplete="off" >
                    <TextField id="employeesNumber"
                        placeholder={t("num_of_employees_placeholder")}
                        name="employeesNumber"
                        type="number"
                        value={formik.values.employeesNumber}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.employeesNumber &&
                            Boolean(formik.errors.employeesNumber)
                        }
                        helperText={
                            formik.touched.employeesNumber &&
                            formik.errors.employeesNumber
                        }
                    />
                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!formik.values.employeesNumber ? "disabled" : "active"}`}
                            title={t("next")}
                            disabled={!formik.values.employeesNumber}
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
