
import React, { useEffect, useState } from 'react'
import { DoneSuccessfully, LoadingBtn, Popup, Spinner, TextField, withErrorHandler } from '../../components'
import { t } from 'i18next'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import * as Sentry from '@sentry/react';
import { ServiceProxy } from '../../ServiceProxy'
import { useFormik } from 'formik';
import { PartnerLeadFormSchema } from '../quotationEngine/schema';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const PartnerLeadForm = () => {
	const [visible, setVisible] = useState(false)
	const [send, setSend] = useState(false)
	const { form_uuid } = useParams()
	const [title, setTitle] = useState('')
	const [insuranceTypes, setInsuranceTypes] = useState([])
	const titlePopup = <div className='text-center'><DoneSuccessfully />{t('send_successfully')}</div>
	useEffect(() => {
		ServiceProxy.PartnerLeadFormService.getForm(form_uuid)
			.then(response => {
				setTitle(response.data.title)
				setInsuranceTypes(response.data.policy_types)
			})
			.catch(error => {
				Sentry.captureException(error);
			});
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	const submit = (values) => {
		setSend(true)
		ServiceProxy.PartnerLeadFormService.submitForm(form_uuid, values)
			.then((response) => {
				response.status === 200 && setVisible(true)
				setSend(false)
			})
			.catch(error => {
				Sentry.captureException(error);
				setSend(false)
			});
	}
	const formik = useFormik({
		initialValues: {
			full_name: '',
			phone_number: '',
			insurance_type: undefined,
			comment: '',
		},
		validationSchema: PartnerLeadFormSchema,
		onSubmit: (values) => {
			submit({ ...values, insurance_type: Number(formik.values.insurance_type) })
		}
	});
	return (
		<div className="container" id="page-wrapper">
			{insuranceTypes.length <= 0 ? <Spinner /> :
				<div className="main-layout">
					<div className='partner'>
						<h3 className='partner-title text-center'>{title}</h3>
					</div>
					<form onSubmit={formik.handleSubmit} autoComplete="off" >
						<div className='partner-lead'>
							<div className='partner-lead-container'>
								<h4>{t("full_name")}</h4>
								<TextField id="full_name"
									placeholder={t("full_name")}
									name="full_name"
									onChange={formik.handleChange}
									value={formik.values.full_name}
									error={
										formik.touched.full_name &&
										Boolean(formik.errors.full_name)
									}
									helperText={
										formik.touched.full_name &&
										formik.errors.full_name
									}
								/>
							</div>
							<div className='partner-lead-container'>
								<h4>{t("phone_number")}</h4>
								<TextField id="phone_number"
									placeholder={t("phone_placeholder")}
									name="phone_number"
									value={formik.values.phone_number}
									onChange={formik.handleChange}
									error={
										formik.touched.phone_number &&
										Boolean(formik.errors.phone_number)
									}
									helperText={
										formik.touched.phone_number &&
										formik.errors.phone_number
									}
								/>
							</div>
						</div>
						{insuranceTypes.length > 0 &&
							<div className='partner-lead-container'>
								<h4>{t("insurance_type")}</h4>
								<RadioGroup row
									aria-labelledby="demo-radio-buttons-group-label"
									name="insurance_type"
								>
									<div className='partner-lead' >
										{insuranceTypes.map((type, index) => (
											<FormControlLabel value={type.policy_type} checked={type.policy_type === Number(formik.values.insurance_type)} key={index}
												onChange={formik.handleChange} control={<Radio />} label={type.display_name} sx={{ minWidth: '15%' }} />
										))}
									</div>
								</RadioGroup>
							</div>
						}
						<div className='partner-lead-container'>
							<h4>{t('additional_details')}</h4>
							<TextField id="comment"
								placeholder={t("additional_details")}
								name="comment"
								value={formik.values.comment}
								onChange={formik.handleChange}
							/>
						</div>
						<div className='partner-lead-buttons'>
							<div className='btnDiv'>
								<button type='submit' className={`btn ${!formik.values.full_name || !formik.values.phone_number || !formik.values.insurance_type ? "disabled" : "active"}`}
									disabled={send}>
									<div className='lose-discount'>{send ? <LoadingBtn /> : t('send')}</div>
								</button>
							</div>
							<div className='btnDiv'>
								<button type="reset" className='btn' onClick={() => formik.resetForm()}>
									<div className='lose-discount'>{t('delete_form')}</div>
								</button>
							</div>
						</div>
					</form>
				</div>
			}
			<Popup show={visible} title={titlePopup} description={t('thank_you_message_span')} onClick={() => {
				formik.resetForm()
				setVisible(false)
			}}
				descStyle='text-center' />
		</div>
	)
}
export default withErrorHandler(PartnerLeadForm, axios)