// axios headers configuration to add or remove headers from api.
import { getCurrentLanguage } from '../config/i18n'

export const removeDefaultHeaders = (axios) => {
  delete axios.defaults.headers.common['Authorization'];
  
};
export const addAxiosDefaultHeaders = (axios) => {
  const lang = getCurrentLanguage()
  axios.defaults.headers.common['Content-Language'] = lang === "ar" ? "AR" : "EN";
  axios.defaults.headers.common['Authorization'] = `auth ${process.env.REACT_APP_API_KEY}`;
};
