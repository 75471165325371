import React from 'react';
import { useTranslation } from "react-i18next";
import { CheckoutCard } from '../../../components/ui'
import VFCashLogo from '../../../assets/img/VF_cash_logo.png'
import { localStorageKeys, LocalStorageService } from '../../../application/services';
import { paymentMethodsEnum, vodafoneCashPromo } from '../../../config/enums';
export function PaymentMethods({ data, ChangePaymentMethod, paymentMethod }) {
    const { t } = useTranslation();
    const VFCashPromo = LocalStorageService.get(localStorageKeys.promoCode) === vodafoneCashPromo
    return (
        <div className="payment-wrapper">
            <h3>{t("payment_methods_title")}</h3>
            <div className="cards-wrapper">
                {data && data.map((item, index) => {
                    return <CheckoutCard
                        title={item.value === paymentMethodsEnum.Wallet && VFCashPromo ? '' : item.name}
                        icon={item.value === paymentMethodsEnum.Wallet && VFCashPromo ? VFCashLogo : item.img_link}
                        vfIcon={item.value === paymentMethodsEnum.Wallet && VFCashPromo}
                        onClick={() => ChangePaymentMethod(item.id, item.value)}
                        selected={paymentMethod === item.id}
                        key={index} />
                })}
            </div>
        </div>
    )
}
