import React, { useEffect, useState } from 'react'
import * as Sentry from "@sentry/react";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { useFormik } from 'formik';
import axios from 'axios';
import { Spinner, PolicySummaryCard, PolicySummaryCardFromPurchase, withErrorHandler } from '../../components';
import { paymentTypeEnum, paymentMethodsEnum } from '../../config/enums';
import { PaymentTypes, PaymentMethods, MobileWalletPhoneNumber } from '../checkout/components';
import { LocalStorageService, localStorageKeys } from "../../application/services";

import { phoneNumberValidationSchema } from '../quotationEngine/schema';
import { splitParams } from '../../helpers';
import { ServiceProxy } from "../../ServiceProxy";

const PurchaseLink = (props) => {
    const [loading, setLoading] = useState(true)
    const [token, setToken] = useState(null)
    const [paymentType, setPaymentType] = useState(paymentTypeEnum.FullAmount)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentMethodValue, setPaymentMethodValue] = useState('')
    const [paymentMethods, setPaymentMethods] = useState(null)
    const [amount, setAmount] = useState(null)
    const [purchaseData, setPurchaseData] = useState(null)
    const [pay, setPay] = useState(false)
    const formik = useFormik({
        initialValues: {
            phoneNumber: LocalStorageService.get(localStorageKeys.mobileWalletNumber) || '',
        },
        validationSchema: phoneNumberValidationSchema,
        onSubmit: (values) => {
            LocalStorageService.set(localStorageKeys.mobileWalletNumber, values.phoneNumber);
        },
    });
    const getPaymentLinkData = (token) => {
        ServiceProxy.PurchaseService.getPaymentLinkData(token)
            .then(response => {
                if (response?.data) {
                    const result = response.data
                    setPaymentMethods(result.payment_methods)
                    if (result.payment_methods.full_payment.length === 0 && result.payment_methods.instalments.length > 0) {
                        setPaymentType(paymentTypeEnum.Installments)
                    }
                    if (result.payment_methods.instalments.length === 1) {
                        ChangePaymentMethod(result.payment_methods.instalments[0].id, result.payment_methods.instalments[0].value)

                    }
                    if (result.payment_methods.full_payment.length === 1) {
                        ChangePaymentMethod(result.payment_methods.full_payment[0].id, result.payment_methods.full_payment[0].value)
                    }

                    setAmount(result.amount_to_pay_pound)
                    setPurchaseData(result.purchase)
                    LocalStorageService.set(localStorageKeys.purchaseRef, result?.purchase?.ref)
                }
            })
            .then(() => setLoading(false))
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });
    }
    useEffect(() => {
        disableBrowserBackButton()
        const paramsDict = splitParams(window.location.search)
        if (paramsDict['token']) {
            setToken(paramsDict['token'])
            LocalStorageService.set(localStorageKeys.purchaseToken, paramsDict['token'])
            getPaymentLinkData(paramsDict['token'])
        }

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const ChangePaymentType = (type) => {
        if (!pay) {
            setPaymentType(type)
            if (type === paymentTypeEnum.Installments && paymentMethods.instalments.length === 1) {
                ChangePaymentMethod(paymentMethods.instalments[0].id, paymentMethods.instalments[0].value)
            }
            else if (type !== paymentTypeEnum.Installments && paymentMethods.full_payment.length === 1) {
                ChangePaymentMethod(paymentMethods.full_payment[0].id, paymentMethods.full_payment[0].value)

            } else {
                setPaymentMethod(null)
                setPaymentMethodValue('')
            }
        }
    }
    const ChangePaymentMethod = (method, value) => {
        if (!pay) {
            setPaymentMethod(method)
            setPaymentMethodValue(value)
            LocalStorageService.set(localStorageKeys.paymentMethod, method)
            LocalStorageService.set(localStorageKeys.paymentMethodValue, value)
        }
    }
    const setPaymentValues = () => {
        const data = {
            psp_id: paymentMethod,
            wallet_phone_number: formik.values.phoneNumber || null,
        }
        return data
    }
    const PayQuotaion = () => {
        if (token) {
            formik.handleSubmit()
            const data = setPaymentValues()
            setPay(true)
            ServiceProxy.PurchaseService.submitPayment(data, token)
                .then(response => {
                    if (response && response.data) {
                        const result = response.data;
                        if (result.iframe_url) {
                            props.history.push({ pathname: '/buy-policy', state: { url: result.iframe_url, purchaseLink: true }, search: props.location.search })
                        }
                        else if (result.redirect_url) {
                            window.location.href = result.redirect_url
                        }
                        else if (result.kiosk_reference) {
                            props.history.push({ pathname: '/kiosk-code', state: { code: result.kiosk_reference }, search: props.location.search })
                        }
                        else {
                            props.history.push({ pathname: '/success', search: props.location.search })
                        }
                    }
                    setPay(false)
                })
                .catch(function (error) {
                    setPay(false)
                    Sentry.captureException(error);
                });
        }
    }
    return (
        <div className="container" id="page-wrapper">
            {loading ? <Spinner /> :
                paymentMethods ?
                    <div className="checkout checkout-wrapper">

                        <div className='flex-box gap-120'>
                            <div className='flex-basis-50 pb-60'>
                                <PolicySummaryCardFromPurchase
                                    installment={paymentType === paymentTypeEnum.Installments}
                                    policyPrice={amount}
                                    name={purchaseData && purchaseData.name}
                                    insuranceCompany={purchaseData && purchaseData.insurance_company}
                                />
                                <PaymentTypes value={paymentType} ChangePaymentType={ChangePaymentType} paymentMethods={paymentMethods} />
                                <PaymentMethods
                                    data={paymentType === paymentTypeEnum.FullAmount ? paymentMethods.full_payment : paymentMethods.instalments}
                                    ChangePaymentMethod={ChangePaymentMethod}
                                    paymentMethod={paymentMethod} />
                                {paymentMethodValue === paymentMethodsEnum.Wallet &&
                                    <MobileWalletPhoneNumber formik={formik} />
                                }

                            </div>
                            <div className='flex-basis-35'>
                                <PolicySummaryCard
                                    installment={paymentType === paymentTypeEnum.Installments}
                                    activeBtn={(paymentMethodValue && paymentMethodValue !== paymentMethodsEnum.Wallet)
                                        || (paymentMethodValue && paymentMethodValue === paymentMethodsEnum.Wallet && formik.values.phoneNumber && Boolean(formik.errors.phoneNumber) === false)}
                                    PayQuotaion={PayQuotaion}
                                    policyPrice={amount}
                                    discountedPolicyPrice={amount}
                                    packageName={purchaseData && purchaseData.policy_type}
                                    name={purchaseData && purchaseData.name}
                                    insuranceCompany={purchaseData && purchaseData.insurance_company}
                                    pay={pay} />
                            </div>
                        </div>
                    </div>
                    : undefined}
        </div>
    )

}
export default withErrorHandler(PurchaseLink, axios)