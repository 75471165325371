import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper, Error, Greeting } from '../../../components';
import { homeTypeEnum, insuranceTypeEnum, policySteps } from '../../../config/enums';
import { NameField, CustomDOB, InsuranceProviders, CarModels, EmployeesAgeField, GenderField, HomeValue, ContentValue, PhoneNumberField } from '../components';
import { localStorageKeys, LocalStorageService, MixpanelService } from '../../../application/services';

export default function StepThree(props) {
    const { t } = useTranslation();
    const [invalidRoute, setsetInvalidRoute] = useState(null)
    const activeStep = 3
    const [insuranceType, setInsuranceType] = useState(null)
    const ownershipType = LocalStorageService.get(localStorageKeys.homeType)
    const handleClickNext = () => {
        LocalStorageService.get(localStorageKeys.businessInsurance) ?
            props.history.push({ pathname: `/${props.location.state?.insurance_type}/loading-packages`, state: { insurance_type: props.location.state.insurance_type }, search: props.location.search })
            :
            props.history.push({ pathname: `/${props.location.state?.insurance_type}/step-four`, state: { insurance_type: props.location.state.insurance_type }, search: props.location.search })
    }
    const handleSkipStep = () => {
        props.history.replace({ pathname: `/${props.location.state?.insurance_type}/step-four`, state: { insurance_type: props.location.state?.insurance_type, prev_path: props.location.pathname }, search: props.location.search })
    }
    useEffect(() => {
        if (props.location.state?.insurance_type !== props.match.params?.type) {
            setsetInvalidRoute(true)
        }
        else {
            setInsuranceType(props.location.state?.insurance_type)
            MixpanelService.getInstance().qeStepThreeViewed(props.location.state?.insurance_type)
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container" id="page-wrapper">
            {!navigator.onLine ?
                <Error message={t("connection_error_message")} btnText={t("try_again_text")} onClick={() => window.location.reload()} /> :
                invalidRoute ? <Error message={t("invalid_path_message")} btnText={t("home_btn")} autoRedirect /> :
                    <div className="step pl-15 pr-15 wrapper">
                        <div className="head-wrapper">
                            <div>
                                <Greeting />
                                <Stepper activeStep={activeStep} numOfSteps={policySteps[insuranceType]} />
                            </div>
                        </div>
                        {insuranceType === insuranceTypeEnum.Life ? <NameField handleClickNext={handleClickNext} /> :
                            insuranceType === insuranceTypeEnum.Medical ? <NameField handleClickNext={handleClickNext} medical /> :
                                insuranceType === insuranceTypeEnum.Motor ? <CarModels handleClickNext={handleClickNext} handleSkipStep={handleSkipStep} /> :
                                    insuranceType === insuranceTypeEnum.Travel ? <CustomDOB handleClickNext={handleClickNext} /> :
                                        insuranceType === insuranceTypeEnum.Home ? ownershipType === homeTypeEnum.Owner ? <HomeValue handleClickNext={handleClickNext} /> : <ContentValue handleClickNext={handleClickNext} /> :
                                            insuranceType === insuranceTypeEnum.SMEMedical ? <EmployeesAgeField handleClickNext={handleClickNext} /> :
                                                insuranceType === insuranceTypeEnum.InternationalMedical ? <GenderField handleClickNext={handleClickNext} /> :
                                                    LocalStorageService.get(localStorageKeys.businessInsurance) ? <PhoneNumberField handleClickNext={handleClickNext} title={t("Can_you_share_your")} /> :
                                                        undefined}
                        {!LocalStorageService.get(localStorageKeys.businessInsurance) && <InsuranceProviders type={props.match.params?.type} />}
                    </div>

            }
        </div>
    )
}
