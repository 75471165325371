import axios from "axios";
import { removeDefaultHeaders, addAxiosDefaultHeaders } from '../../helpers'
export class PaymentService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/payment/v2/qe`
    }
    getPaymentMethods(values) {
        return axios.post(`${this._baseUrl}/psps/payment-methods`, values);
    }
    validateHMAC(values, accessToken) {
        removeDefaultHeaders(axios)
        const result = axios({
            method: "post",
            url: `${this._baseUrl}/hmac/check`,
            data: values,
            headers: {
                Authorization: `auth ${accessToken}`,
            },
        })
        addAxiosDefaultHeaders(axios)
        return result
    }
}