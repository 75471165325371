import React, { useEffect, useState } from 'react';
import detect from "detect.js";
import Close from '../../../assets/img/closeX.svg';
import AppStore from '../../../assets/img/appleStore.svg';
import GooglePLay from '../../../assets/img/googleStore.svg';
import { useTranslation } from "react-i18next";
import { insuranceTypeEnum } from '../../../config/enums';
import { LocalStorageService, localStorageKeys } from '../../../application/services';
export const SmartAppBanner = () => {
    const { t } = useTranslation();
    const [hideBanner, setHideBanner] = useState(LocalStorageService.get(localStorageKeys.hideBanner) || false)
    const [osFamily, setOsFamily] = useState(null)
    const handleAppLink = () => {
        var ua = detect.parse(navigator.userAgent);
        if (ua.os && ua.os.family) {
            if (ua.os.family === 'Android' && !navigator.userAgent.includes('wv')) {
                setOsFamily('Android')
            }
            else if (ua.os.family === 'iOS' && !navigator.userAgent.includes('wv')) {
                setOsFamily('iOS')
            }
            else if (navigator.userAgent.includes('wv') || window.location.href.includes(insuranceTypeEnum.App) || window.location.href.includes('loading-packages')) {
                setHideBanner(true)
            }
            else {
                setHideBanner(true)
            }
        }
        else {
            setHideBanner(true)
        }
    }
    useEffect(() => {
        handleAppLink()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            {!hideBanner && <div className="banner">
                <img src={Close} width={24} height={24} alt='closeIcon' onClick={() => {
                    setHideBanner(true)
                    LocalStorageService.set(localStorageKeys.hideBanner, true)
                }} />
                <b className='text-center'>{t("get_app")}</b>
                {osFamily === 'iOS' && <a href={process.env.REACT_APP_IOS} target="_blank" rel="noreferrer"><img className='icon' src={AppStore} alt='AmenliAppIos' /></a>}
                {osFamily === 'Android' && <a href={process.env.REACT_APP_ANDROID} target="_blank" rel="noreferrer"><img className='icon' src={GooglePLay} alt='AmenliAppAndroid' /></a>}
            </div>}
        </>
    );
};


