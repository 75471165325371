import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import { Button, TextField } from '../../../components/ui';
import { nameValidationSchema, passportNameValidationSchema } from '../schema';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
import { insuranceTypeEnum } from '../../../config/enums';
export function NameField(props) {
    const { t } = useTranslation();
    const insuranceTypeId = LocalStorageService.get(localStorageKeys.insuranceTypeID)
    const formik = useFormik({
        initialValues: {
            name: LocalStorageService.get(localStorageKeys.userName) || "",
        },
        validationSchema: insuranceTypeId === insuranceTypeEnum.TravelInsuranceId ? passportNameValidationSchema : nameValidationSchema,
        onSubmit: (values) => {
            props.handleClickNext()
            LocalStorageService.set(localStorageKeys.userName, values.name);
            MixpanelService.getInstance().userNameFieldClickNext(insuranceTypeId, { user_name: values.name })
        },
    });
    return (
        <div>
            <div className="heading mt-45">
                <p>{t("can_you_tell_me")}</p>
                <h3>{t("ask_for_name")}</h3>
            </div>
            <div className="mt-30">
                <form onSubmit={formik.handleSubmit} autoComplete="off" >
                    <TextField id="name"
                        placeholder={t("name_placeholder")}
                        value={formik.values.name}
                        onFocus={() => MixpanelService.getInstance().clickUserNameField(insuranceTypeId)}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.name &&
                            Boolean(formik.errors.name)
                        }

                        helperText={
                            (formik.touched.name && formik.errors.name) ||
                            (insuranceTypeId === insuranceTypeEnum.TravelInsuranceId && t("passsport_name_helper"))
                        }
                        name="name"
                    />
                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!formik.values.name ? "disabled" : "active"}`}
                            title={t("next")}
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
