import React from 'react'
import Lottie from 'react-lottie-player';
import animationSuccess from '../../../assets/success.json'
import animationLoadData from '../../../assets/loader.json'
import animationFailure from '../../../assets/error.json'
import etisalatLoader from '../../../assets/etisalat_loader.json'
import { etisalatHost } from '../../../config/enums';
import animationLoadingBtn from '../../../assets/loadingBtn.json'

export const DoneSuccessfully = () => {
    return <div className="lottie-wrapper" >
        <Lottie
            loop
            play
            animationData={animationSuccess}
            style={{
                height: 175,
                width: 175
            }}
        />

    </div>
}
export const WaitData = () => {
    return <div className="lottie-wrapper" >
        <Lottie
            loop
            play
            animationData={etisalatHost ? etisalatLoader : animationLoadData}
            style={{
                height: 202,
                width: 202
            }}
        />
    </div>
}
export const LoadingBtn = () => {
    return <div className="lottie-wrapper" >
        <Lottie
            loop
            play
            animationData={animationLoadingBtn}
            style={{
                height: 50,
            }}
        />
    </div>
}

export const Failed = () => {
    return <div className="lottie-wrapper" >
        <Lottie
            loop
            play
            animationData={animationFailure}
            style={{
                height: 175,
                width: 175
            }}
        />
    </div>
}