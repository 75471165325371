import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRadioButton } from '../../../components/ui';
import { LocalStorageService, localStorageKeys } from '../../../application/services';
import { homeTypeEnum } from '../../../config/enums';
import { MixpanelService } from '../../../application/services';

export function HomeType(props) {
    const { t } = useTranslation();
    const [homeType, setHomeType] = useState(LocalStorageService.get(localStorageKeys.homeType))
    const handleChangehomeType = (e) => {
        setHomeType(e.target.value)
        MixpanelService.getInstance().selectTenantType({ tenant_type: e.target.value })
        setTimeout(() => {
            props.handleClickNext()
            LocalStorageService.set(localStorageKeys.homeType, e.target.value);
        }, 500);

    }
    return (
        <div>
            <div className="heading mt-45 ">
                <p>{props.title ? props.title : t("can_you_tell_me")} </p>
                <h3>{t("home_type_label")}</h3>
            </div>
            <div className="row">
                <div className="col-12 col-md">
                    <CustomRadioButton label={t("home_type_owner")}
                        id="owner"
                        value="owner"
                        name="homeType"
                        onChange={handleChangehomeType}
                        checked={homeType === homeTypeEnum.Owner}
                        onClick={handleChangehomeType}
                    />
                </div>
                <div className="col-12 col-md">
                    <CustomRadioButton label={t("home_type_tenant")}
                        id="tenant"
                        value="tenant"
                        name="homeType"
                        onChange={handleChangehomeType}
                        checked={homeType === homeTypeEnum.Tenant}
                        onClick={handleChangehomeType}
                    />
                </div>
            </div>
        </div>
    )
}
