import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { RadioButton, InformationModal, Spinner, Error, withErrorHandler, Heading } from '../../../components';
import { LocalStorageService, localStorageKeys, MixpanelService } from '../../../application/services';
import { etisalatHost, insuranceTypeEnum, languageEnum, mongezPromo, freeHomeInsurancePromo } from '../../../config/enums';
import { getProductType } from '../../../helpers';
import { ServiceProxy } from '../../../ServiceProxy';
import Avatar from '../../../assets/img/avatar.svg';
import Motor from '../../../assets/img/motor.svg';
import Life from '../../../assets/img/life.svg';
import Medical from '../../../assets/img/medical.svg';
import Travel from '../../../assets/img/airport.svg';
import Home from '../../../assets/img/home.svg';
import MedicalBlue from '../../../assets/img/medical_blue.svg';
import LifeBlue from '../../../assets/img/life_blue.svg';
import MotorBlue from '../../../assets/img/motor_blue.svg';
import TravelBlue from '../../../assets/img/airport_blue.svg';
import HomeBlue from '../../../assets/img/home_blue.svg';
import LogoEN from '../../../assets/img/logoEN.svg';
import LogoAR from '../../../assets/img/logoAR.svg';
import { getCurrentLanguage } from '../../../config/i18n';
import SMEMedical from '../../../assets/img/sme_medical.svg';
import SMEMedicalBlue from '../../../assets/img/sme_medical_blue.svg';
import { splitParams } from '../../../helpers';
import InternationalMedical from '../../../assets/img/international_medical.svg';
import InternationalMedicalBlue from '../../../assets/img/international_medical_blue.svg';
export const policiesIcons = {
    0: Life,
    2: Travel,
    3: Home,
    5: Medical,
    6: Motor,
    20: SMEMedical,
    18: InternationalMedical
}
export const policiesActiveIcon = {
    0: LifeBlue,
    2: TravelBlue,
    3: HomeBlue,
    5: MedicalBlue,
    6: MotorBlue,
    20: SMEMedicalBlue,
    18: InternationalMedicalBlue
}
export const policiesEnum = {
    0: insuranceTypeEnum.Life,
    2: insuranceTypeEnum.Travel,
    3: insuranceTypeEnum.Home,
    5: insuranceTypeEnum.Medical,
    6: insuranceTypeEnum.Motor,
    20: insuranceTypeEnum.SMEMedical,
    18: insuranceTypeEnum.InternationalMedical,
    14: insuranceTypeEnum.ProfessionalLiabilityInsurance,
    15: insuranceTypeEnum.SuretyAndFidelityInsurance,
    8: insuranceTypeEnum.FireInsurance,
    10: insuranceTypeEnum.FreightInsurance,
    21: insuranceTypeEnum.CorpLifeInsurance,
    22: insuranceTypeEnum.CorpMotorInsurance
}

class InsuranceType extends Component {
    state = {
        insurance_type: null,
        insurance_type_id: null,
        query_src: "",
        query_promo: "",
        show: false,
        loading: true,
        policies: [],
        ip: null,
        groups: [],
        is_business_insurance: LocalStorageService.get(localStorageKeys.businessInsurance) || false

    }
    handleChangeInsuranceType = (event) => {
        this.setState({
            insurance_type: event.target.value,
            insurance_type_id: event.target.id
        }, () => {
            setTimeout(() => {
                this.handleClickNext()
            }, 500);
        });
    }
    handleClickCard = (id, value) => {
        this.setState({
            insurance_type: value,
            insurance_type_id: id,
            is_business_insurance: false
        }, () => {
            setTimeout(() => {
                this.handleClickNext()
            }, 500);
        })


    }
    setSearchPrams = () => {
        let value = ''
        if (LocalStorageService.get(localStorageKeys.src) && LocalStorageService.get(localStorageKeys.prm)) {
            value = `${LocalStorageService.get(localStorageKeys.src)}&${LocalStorageService.get(localStorageKeys.prm)}`
        }
        else if (LocalStorageService.get(localStorageKeys.src)) {
            value = `${LocalStorageService.get(localStorageKeys.src)}`
        }
        else if (LocalStorageService.get(localStorageKeys.prm)) {
            value = `${LocalStorageService.get(localStorageKeys.prm)}`
        }
        return value
    }
    handleClickNext = () => {
        const prams = this.setSearchPrams()
        this.props.history.push({
            pathname: `/${this.state.insurance_type}/step-one`,
            state: { insurance_type: this.state.insurance_type },
            search: this.props.location.search || prams
        })
        LocalStorageService.set(localStorageKeys.insuranceTypeID, this.state.insurance_type_id);
        LocalStorageService.set(localStorageKeys.insuranceType, this.state.insurance_type);
        LocalStorageService.delete(localStorageKeys.businessInsurance);
        MixpanelService.getInstance().selectProduct({ type: getProductType(this.state.insurance_type) })

    }
    dateCompare = (day) => {
        const date1 = new Date(day);
        const date2 = new Date();
        if (date1 >= date2 || date1.getDate() === date2.getDate()) {
            return true
        } else {
            return false
        }
    }
    redirectToCertainTypeOfInsurance = (insuranceType) => {
        this.props.history.push({
            pathname: `/${insuranceType}/step-one`,
            state: { insurance_type: `${insuranceType}` },
        })
    }
    getInsuranceType = (insuranceType) => {
        if (insuranceType === insuranceTypeEnum.MotorInsuranceId) {
            this.redirectToCertainTypeOfInsurance(insuranceTypeEnum.Motor)
        } else if (insuranceType === insuranceTypeEnum.MedicalInsuranceId) {
            this.redirectToCertainTypeOfInsurance(insuranceTypeEnum.Medical)
        } else if (insuranceType === insuranceTypeEnum.TravelInsuranceId) {
            this.redirectToCertainTypeOfInsurance(insuranceTypeEnum.Travel)
        } else if (insuranceType === insuranceTypeEnum.HomeInsuranceId) {
            this.redirectToCertainTypeOfInsurance(insuranceTypeEnum.Home)
        } else if (insuranceType === insuranceTypeEnum.SMEMedicalInsuranceId) {
            this.redirectToCertainTypeOfInsurance(insuranceTypeEnum.SMEMedical)
        } else if (insuranceType === insuranceTypeEnum.LifeInsuranceId) {
            this.redirectToCertainTypeOfInsurance(insuranceTypeEnum.Life)
        } else if (insuranceType === insuranceTypeEnum.InternationalMedicalId) {
            this.redirectToCertainTypeOfInsurance(insuranceTypeEnum.InternationalMedical)
        }
        let checkInsuranceID = insuranceTypeEnum.MotorInsuranceId === insuranceType ? insuranceTypeEnum.MotorInsuranceId :
            insuranceTypeEnum.MedicalInsuranceId === insuranceType ? insuranceTypeEnum.MedicalInsuranceId :
                insuranceTypeEnum.TravelInsuranceId === insuranceType ? insuranceTypeEnum.TravelInsuranceId :
                    insuranceTypeEnum.HomeInsuranceId === insuranceType ? insuranceTypeEnum.HomeInsuranceId :
                        insuranceTypeEnum.SMEMedicalInsuranceId === insuranceType ? insuranceTypeEnum.SMEMedicalInsuranceId :
                            insuranceTypeEnum.LifeInsuranceId === insuranceType ? insuranceTypeEnum.LifeInsuranceId :
                                insuranceTypeEnum.InternationalMedicalId === insuranceType ? insuranceTypeEnum.InternationalMedicalId : null;
        let checkInsuranceType = insuranceTypeEnum.MotorInsuranceId === insuranceType ? insuranceTypeEnum.Motor :
            insuranceTypeEnum.MedicalInsuranceId === insuranceType ? insuranceTypeEnum.Medical :
                insuranceTypeEnum.TravelInsuranceId === insuranceType ? insuranceTypeEnum.Travel :
                    insuranceTypeEnum.HomeInsuranceId === insuranceType ? insuranceTypeEnum.Home :
                        insuranceTypeEnum.SMEMedicalInsuranceId === insuranceType ? insuranceTypeEnum.SMEMedical :
                            insuranceTypeEnum.LifeInsuranceId === insuranceType ? insuranceTypeEnum.Life :
                                insuranceTypeEnum.InternationalMedicalId === insuranceType ? insuranceTypeEnum.InternationalMedical : null
        LocalStorageService.set(localStorageKeys.insuranceTypeID, checkInsuranceID);
        LocalStorageService.set(localStorageKeys.insuranceType, checkInsuranceType);
    }
    getUserIP = () => {
        ServiceProxy.UtilityService.getIP()
            .then(res => {
                if (res && res.data) {
                    this.setState({ ip: res.data.data.ip_address })
                    MixpanelService.getInstance().setMixpanelUserProperties({
                        IP: res.data.data.ip_address,
                        City: res.data.data.city,
                        latitude: res.data.data.latitude,
                        longitude: res.data.data.longitude,
                        Region: res.data.data.region
                    })

                }
            })
            .catch(e => {
                Sentry.captureException(e);
            })

    }
    componentDidMount() {
        this.getUserIP()
        let insuranceType = Number(new URLSearchParams(this.props.location.search).get(insuranceTypeEnum.Choice))
        this.setState({
            insurance_type_id: LocalStorageService.get(localStorageKeys.insuranceTypeID),
            insurance_type: LocalStorageService.get(localStorageKeys.insuranceType)
        })
        let promo = this.getQueryParams();
        if (!promo) {
            promo = LocalStorageService.get(localStorageKeys.promoCode)
        }
        ServiceProxy.InsuranceService.getPolicies({ promo_code: promo })
            .then(response => {
                if (response.data) {
                    const uniqueGroups = new Set();
                    const groupsArray = response.data
                        .filter(item => item.groups)
                        .flatMap(item => item.groups)
                        .filter(group => {

                            const isDuplicate = uniqueGroups.has(group.display_name);
                            uniqueGroups.add(group.display_name);
                            return !isDuplicate;
                        });
                    this.setState({ groups: groupsArray })
                    MixpanelService.getInstance().pageViewed()
                    this.setState({ policies: response.data, loading: false })
                    if (insuranceType === 0) {
                        disableBrowserBackButton()
                    } else {
                        const found = response.data.some(el => el.policy_type === insuranceType);
                        if (found) {
                            this.getInsuranceType(insuranceType)
                        } else {
                            this.handleInvalidChoice()
                        }
                    }
                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch(function (error) {

                Sentry.captureException(error);
            });

    }
    getQueryParams() {
        const paramsDict = splitParams(window.location.search)
        if (paramsDict["src"]) {
            this.setState({ query_src: paramsDict["src"] });
            LocalStorageService.set(localStorageKeys.quotationSource, paramsDict["src"]);
            LocalStorageService.set(localStorageKeys.src, `src=${paramsDict["src"]}`);
        }
        if (paramsDict["prm"]) {
            this.setState({ query_promo: paramsDict["prm"] });
            LocalStorageService.set(localStorageKeys.promoCode, paramsDict["prm"]);
            LocalStorageService.set(localStorageKeys.prm, `prm=${paramsDict["prm"]}`);
            if (mongezPromo.PromoValue.includes(paramsDict["prm"]) && this.dateCompare(mongezPromo.PromoDeadLine)) {
                this.setState({ show: true })
            }
        }
        if (paramsDict["is_app"]) {
            LocalStorageService.set(localStorageKeys.app, `is_app=${paramsDict["is_app"]}`);
        }
        if (paramsDict["external_reference"]) {
            LocalStorageService.set(localStorageKeys.etisalatExternalReference, `${paramsDict["external_reference"]}`);
        }
        if (paramsDict["utm_source"]) {
            LocalStorageService.set(localStorageKeys.campaignUtmSource, paramsDict["utm_source"]);
            LocalStorageService.set(localStorageKeys.utmSource, `utm_source=${paramsDict["utm_source"]}`);
        }
        return paramsDict["prm"];
    }

    handleInvalidChoice = () => {
        const queryParams = new URLSearchParams(this.props.location.search)
        queryParams.delete(insuranceTypeEnum.Choice)
        this.props.history.replace({
            search: queryParams.toString(),
        })
    }
    handleBusinessTypes = () => {
        const prams = this.setSearchPrams()
        this.props.history.push({
            pathname: `/business-types`,
            search: this.props.location.search || prams,
            state: { policies: this.state.policies }
        })
        LocalStorageService.set(localStorageKeys.businessInsurance, this.state.is_business_insurance);
        LocalStorageService.delete(localStorageKeys.insuranceTypeID);
        LocalStorageService.delete(localStorageKeys.insuranceType);
    }
    handleClickGroup = () => {
        this.setState({
            is_business_insurance: true,
            insurance_type: null,
            insurance_type_id: null,

        }, () => {
            setTimeout(() => {
                this.handleBusinessTypes()
            }, 500);
        })
    }
    render() {
        const { t } = this.props;
        const { insurance_type_id, loading, policies, groups } = this.state;
        let policies_card = null;
        const isSmallScreen = window.innerWidth <= 766
        if (policies.length > 0) {
            policies_card = policies.filter(item => item.groups.length === 0).map((policy, index) => {
                return (
                    <RadioButton
                        // onChange={this.handleChangeInsuranceType}
                        key={index}
                        label={policy.display_name}
                        name="insurance_type"
                        value={policy.policy_type}
                        id={policy.policy_type}
                        icon={(window.location.search.includes(freeHomeInsurancePromo) ||
                            LocalStorageService.get(localStorageKeys.promoCode) === freeHomeInsurancePromo) && policy.policy_type === insuranceTypeEnum.MotorInsuranceId ? policiesActiveIcon[policy.policy_type] : policiesIcons[policy.policy_type]}
                        activeIcon={etisalatHost ? policiesIcons[policy.policy_type] : policiesActiveIcon[policy.policy_type]}
                        checked={insurance_type_id === policy.policy_type}
                        onClick={() => this.handleClickCard(policy.policy_type, policiesEnum[policy.policy_type])}
                        child={(window.location.search.includes(freeHomeInsurancePromo) ||
                            LocalStorageService.get(localStorageKeys.promoCode) === freeHomeInsurancePromo) && policy.policy_type === insuranceTypeEnum.MotorInsuranceId}
                        childIcon={HomeBlue}
                        childActiveIcon={HomeBlue}
                        childText={getCurrentLanguage() === languageEnum.Arabic ?
                            <label style={{ gap: isSmallScreen && 5 }} className='display-flex' ><span style={{ marginRight: 7, marginLeft: 10 }}>+</span> <span>{t("free_home_insurance")}{!isSmallScreen ? <br /> : null} <span style={{ fontSize: 14 }}>{t("free")}</span></span></label> :
                            <label style={{ gap: isSmallScreen && 8 }} className='display-flex'><span style={{ marginRight: 5, marginLeft: 7 }}>+</span> <span><span style={{ fontSize: 15 }}>{t("free")}</span>{!isSmallScreen ? <br /> : null} {t("free_home_insurance")}</span></label>
                        }
                    />
                )
            })
        }
        let groups_card = null;
        if (policies.length > 0) {
            groups_card = groups.map((policy, index) => {
                return (
                    <RadioButton
                        // onChange={this.handleChangeInsuranceType}
                        key={index}
                        label={policy.display_name}
                        name="insurance_type"
                        icon={policiesIcons[20]}
                        activeIcon={etisalatHost ? policiesIcons[20] : policiesActiveIcon[20]}
                        checked={this.state.is_business_insurance}
                        onClick={() => this.handleClickGroup()}
                    />
                )
            })
        }
        return (
            <div className="container" id="page-wrapper">
                {!navigator.onLine ?
                    <Error message={t("connection_error_message")} btnText={t("try_again_text")} onClick={() => window.location.reload()} /> :
                    loading ? <Spinner /> :
                        <div className="insurance-type-step pl-15 pr-15 home-wrapper">
                            {!etisalatHost && <div className="info">
                                <div className="user-img">
                                    <img src={Avatar} alt="Amenli" />
                                </div>
                                <div className="greeting">
                                    <span>{t("ahlan_message")}</span>
                                    <br />
                                    <p>{t("start_message")}</p>
                                </div>
                            </div>}
                            <Heading />
                            <div className="insurance-card-wrapper">
                                {policies_card}
                                {groups_card}
                            </div>
                            {etisalatHost ? <div className='by_amenli'>
                                {t('powered_by')}
                                <img alt="Amenli" src={getCurrentLanguage() === languageEnum.English ? LogoEN : LogoAR} />
                            </div>
                                : null}
                        </div>
                }
                <InformationModal show={this.state.show} handleClose={() => this.setState({ show: false })} />

            </div>
        )
    }
}
export default withTranslation()(withRouter(withErrorHandler(InsuranceType, axios)));

