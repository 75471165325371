import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { Spinner } from '../../../components/ui';
import { LocalStorageService, localStorageKeys } from "../../../application/services";
import ExpandMore from '../../../assets/img/arrow_down.svg';
import { getCurrentLanguage } from '../../../config/i18n';
import { languageEnum } from '../../../config/enums';
import Countries from "i18n-iso-countries"
import en from "i18n-iso-countries/langs/en.json"
import ar from "i18n-iso-countries/langs/ar.json"

Countries.registerLocale(en)
Countries.registerLocale(ar)

export function DestinationCountry(props) {
    const { t } = useTranslation();
    const lang = getCurrentLanguage()
    const options = lang === languageEnum.Arabic ? Countries.getNames("ar") : Countries.getNames("en")
    const [countryValue, setCountryValue] = useState(null)
    const [loading, setLoading] = useState(true)
    const handleClickNext = () => {
        props.handleClickNext()
        //LocalStorageService.set(localStorageKeys.country, countryValue);
    }

    useEffect(() => {
        setTimeout(async () => {
            if (options) {
                const country = LocalStorageService.get(localStorageKeys.country)
                setCountryValue(country)
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }, 10);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div>
            {loading ? <Spinner /> :
                <>
                    <div className="heading mt-45 mb-30">
                        <p>{props.title ? props.title : t("can_you_tell_me")}</p>
                        <h3>{t("destination_country_label")}</h3>
                    </div>
                    <Autocomplete
                        options={Object.keys(options) || []}
                        getOptionLabel={(option) => options[option] || ''}
                        popupIcon={<img src={ExpandMore} alt="expand" />}
                        onChange={(event, value, reason) => {
                            setCountryValue(value)
                            LocalStorageService.set(localStorageKeys.country, value);
                            setTimeout(() => {
                                handleClickNext()
                            }, 500);
                        }}
                        onClose={(event, reason) => {
                            if (reason === 'selectOption') {
                                setTimeout(() => {
                                    handleClickNext()
                                }, 500);
                            }
                        }}
                        value={countryValue}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                placeholder={t("destination_country_placeholder")}
                            />
                        )}

                    />
                </>
            }
        </div>

    )
}





