import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper, Error, Greeting } from '../../../components';
import { PassportNumber, InsuranceProviders, CustomDOB, PhoneNumberField } from '../components';
import { insuranceTypeEnum, policySteps } from '../../../config/enums';
import { MixpanelService } from '../../../application/services';
export default function StepSix(props) {
    const { t } = useTranslation();
    const activeStep = 6
    const [invalidRoute, setsetInvalidRoute] = useState(null)
    const [insuranceType, setInsuranceType] = useState(null)
    const handleClickNext = () => {
        if (insuranceType && (insuranceType === insuranceTypeEnum.Home)) {
            props.history.push({ pathname: `/${props.location.state?.insurance_type}/loading-packages`, state: { insurance_type: props.location.state.insurance_type }, search: props.location.search })
        }
        else {
            props.history.push({ pathname: `/${props.location.state?.insurance_type}/step-seven`, state: { insurance_type: props.location.state.insurance_type }, search: props.location.search })
        }
    }
    useEffect(() => {
        if (props.location.state?.insurance_type !== props.match.params?.type) {
            setsetInvalidRoute(true)
        }
        else {
            setInsuranceType(props.location.state?.insurance_type)
            MixpanelService.getInstance().qeStepSixViewed(props.location.state?.insurance_type)

        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container" id="page-wrapper">
            {!navigator.onLine ?
                <Error message={t("connection_error_message")} btnText={t("try_again_text")} onClick={() => window.location.reload()} /> :
                invalidRoute ? <Error message={t("invalid_path_message")} btnText={t("home_btn")} autoRedirect /> :
                    <div className="step pl-15 pr-15 wrapper">
                        <div className="head-wrapper">
                            <div>
                                <Greeting />
                                <Stepper activeStep={activeStep} numOfSteps={policySteps[insuranceType]} />
                            </div>
                        </div>
                        {
                            // insuranceType === insuranceTypeEnum.Medical ? <PhoneNumberField handleClickNext={handleClickNext} title={t("Can_you_share_your")} /> :
                            insuranceType === insuranceTypeEnum.Motor ? <CustomDOB handleClickNext={handleClickNext} motor /> :
                                insuranceType === insuranceTypeEnum.Travel ? <PassportNumber handleClickNext={handleClickNext} title={t("Can_you_share_your")} /> :
                                    insuranceType === insuranceTypeEnum.Home ? <PhoneNumberField handleClickNext={handleClickNext} title={t("Can_you_share_your")} /> :
                                        undefined}
                        <InsuranceProviders type={props.match.params?.type} />
                    </div>
            }
        </div>
    )
}
