import React, { useState, useEffect } from 'react'
import { Failed, Button } from '../../ui'
import { Redirect } from 'react-router-dom'
import { useTranslation } from "react-i18next";
export function Error({ message, btnText, onClick, autoRedirect }) {
    const [redirect, setRedirect] = useState(false)
    useEffect(() => {
        if (autoRedirect) {
            setTimeout(() => {
                setRedirect(true)
            }, 3000);
        }
    }, [autoRedirect])
    if (redirect) return <Redirect to="/" />
    return (
        <div >
            <div className="response-wrapper ">
                <Failed />
                <p>{message}</p>
                <div className="btn-wrapper">
                    <button onClick={onClick}>{btnText}</button>
                </div>
            </div>
        </div>
    )
}
export function PaymentFailed({ onClick }) {
    const { t } = useTranslation();
    return (
        <div className="purchase-wrapper wrapper ">
            <Failed />
            <p className='heading failed'>{t("payment_failed_title")}</p>
            <p className='body-16 text-failed'>{t("payment_failed_message")}</p>
            <div className="btn-wrapper static">
                <Button title="Try Again" className="btn-purchase try-again" onClick={onClick} />
            </div>
        </div>
    )
}
