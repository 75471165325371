import i18next from 'i18next';
export const errorCodes = [
    { code: '9020', message: i18next.t('invalid_phone_number') },
    { code: '717', message: i18next.t('invalid_link') },//expired 
    { code: '1221', message: i18next.t('invalid_link') },
    { code: '9010', message: i18next.t('invalid_link') },
    { code: '9011', message: i18next.t('invalid_data_sent') },
    { code: '1400', message: i18next.t('invalid_data_sent') },
    { code: '9021', message: i18next.t('invalid_data_sent') },
    { code: '9019', message: i18next.t('error_processing_payment') }
]
export const urls = ['request-call', 'capture-page-duration', 'capture-page-action']
