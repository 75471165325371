import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import { Button, TextField } from '../../../components/ui';
import { companyNameValidationSchema } from '../schema';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
export function CompanyNameField(props) {
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            companyName: LocalStorageService.get(localStorageKeys.companyName) || "",
        },
        validationSchema: companyNameValidationSchema,
        onSubmit: (values) => {
            props.handleClickNext()
            LocalStorageService.set(localStorageKeys.companyName, values.companyName);
            MixpanelService.getInstance().companyNameFieldClickNext({ company_name: values.companyName })
        },
    });
    return (
        <div>
            <div className="heading mt-45">
                <p>{props.title ? props.title : t("can_you_tell_me")}</p>
                <h3>{t("ask_for_company_name")}</h3>
            </div>
            <div className="mt-30">
                <form onSubmit={formik.handleSubmit} autoComplete="off" >
                    <TextField id="companyName"
                        placeholder={t("company_name_placeholder")}
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.companyName &&
                            Boolean(formik.errors.companyName)
                        }
                        helperText={
                            formik.touched.companyName &&
                            formik.errors.companyName
                        }
                        name="companyName"
                    />
                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!formik.values.companyName ? "disabled" : "active"}`}
                            title={t("next")}
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
