import React from 'react'
import { Button } from '../../ui'
import { useTranslation } from "react-i18next";
export function PolicySummaryCard({
    installment,
    activeBtn,
    PayQuotaion,
    policyPrice,
    discountedPolicyPrice,
    allowDiscountWithInstalment,
    packageName,
    pay,
    insuranceCompany
}) {
    const { t } = useTranslation()
    return (
        <div className={`policy-summary-card ${installment ? "installments" : ''}`}>
            <p className="body-16 mb-0">{t("almost_there")}</p>
            <p className="policy-name subtitle dark-black">{t("buy")}
                <span>{packageName}</span> {t("you_are_subscribing_to")}<br />
                {t("from")} <span>{insuranceCompany}</span> {t('for')}<br />
                {t("amount", {
                    amount: (policyPrice !== discountedPolicyPrice && !installment) ||
                        (installment && policyPrice !== discountedPolicyPrice && allowDiscountWithInstalment) ? Number(discountedPolicyPrice).toLocaleString() :
                        Number(policyPrice).toLocaleString()
                })}
            </p>
            {installment && policyPrice !== discountedPolicyPrice && !allowDiscountWithInstalment ? <p className="policy-date mb-0">{t("installment_note")}</p> : ''}
            <div style={{ height: 48 }}></div>
            <Button title={t("buy_policy_btn")}
                className={activeBtn ? "active-btn" : ''}
                disabled={!activeBtn || pay}
                onClick={PayQuotaion}
                type="submit" />

        </div>
    )
}
export function PolicySummaryCardFromPurchase({
    installment,
    policyPrice,
    insuranceCompany,
    name
}) {
    const { t } = useTranslation()
    return (
        <div className={`policy-summary-card mb-30 purchase-link-web ${installment ? "installments" : ''}`}>
            <p className="body-16 mb-10 ">{t("ahlan")}{`, ${name}!`}</p>
            <p className="policy-date mb-10">{insuranceCompany}</p>
            <p className="policy-name subtitle dark-black">{t("price")}
                <span> {t("amount", {
                    amount: Number(policyPrice).toLocaleString()
                })}</span><br />
            </p>
        </div>
    )
}