import React, { useEffect } from 'react'
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
import { useTranslation } from "react-i18next";
import { DoneSuccessfully } from '../../ui'
import { Link } from 'react-router-dom'
import customers_service_etisalat from '../../../assets/img/customers_service_etisalat.svg'
import { etisalatHost } from '../../../config/enums';
import { insuranceTypeEnum } from '../../../config/enums';
export function Success(props) {
    const { t } = useTranslation();
    return (
        <div className="container" id="page-wrapper">
            <div className="response-wrapper wrapper ">
                {!etisalatHost ?
                    <>
                        <DoneSuccessfully />
                        <p className='mb-1'>{t("success_message")}</p>
                        <p className='mt-1'>{t("information_message")}</p>
                        <div className="btn-wrapper static">
                            <Link to={{ pathname: '/', search: props.location.search }}><button onClick={() => LocalStorageService.clear()}>{t("explore_btn")}</button></Link>
                        </div>
                    </>
                    :
                    <>
                        <img style={{ marginTop: '15%' }} alt='customers_service' src={customers_service_etisalat} />
                        <p><span className='success_message'>{t("success_message")}</span> {t("information_message")}</p>
                        <div className="btn-wrapper ">
                            <button className='btn active'>
                                <div className='lose-discount'>
                                    <Link to={{ pathname: '/', search: props.location.search }} onClick={() => LocalStorageService.clear()}>{t("return_home")}</Link>
                                </div>
                            </button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
export function PolicyPurchased(props) {
    const { t } = useTranslation();
    return (
        <div className="purchase-wrapper wrapper ">
            <DoneSuccessfully />
            <p className='heading success'>{t("payment_success_title")}</p>
            <p className='body-16 text'>{t("payment_success_message")}</p>
            <div className="btn-wrapper static">
                <button className="btn-purchase upload-now" onClick={() => { LocalStorageService.clear(); props.onClick() }}>{t("return_home")}</button>
            </div>
        </div>
    )
}
export function ThankYouPage(props) {
    const { t } = useTranslation();
    const handleClickPay = () => {
        MixpanelService.getInstance().clickPoceedToPayment()
        props.history.push({
            pathname: `/checkout`,
            search: props.location.search,
            state: { insurance_type: LocalStorageService.get(localStorageKeys.insuranceType) }
        })
    }
    const handleClickHome = () => {
        MixpanelService.getInstance().clickReturnHome()
        props.history.push({
            pathname: `/`,
            search: props.location.search
        })
    }
    useEffect(() => {
        MixpanelService.getInstance().thankyouViewed()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="container" id="page-wrapper">
            <div className="response-wrapper wrapper ">
                {!etisalatHost ? <DoneSuccessfully /> : <img style={{ marginTop: '20%' }} alt='customers_service' src={customers_service_etisalat} />}
                <p> <span>{t("thank_you_message_span")} </span> {t("thank_you_message")} </p>
                {!etisalatHost ?
                    LocalStorageService.get(localStorageKeys.insuranceTypeID) === insuranceTypeEnum.SMEMedicalInsuranceId ?
                        <div className="btn-wrapper" >
                            <button onClick={handleClickHome}>{t("buy_another_policy")} </button>

                        </div>
                        :
                        <div className="btn-wrapper" >
                            <button onClick={handleClickPay}>{t("pay_btn")} </button>
                            <div className='lose-discount'>
                                {/* <Link >{t("lose_discount_link")}</Link> */}
                                <Link to={{ pathname: '/', search: props.location.search }} onClick={() => { LocalStorageService.clear(); MixpanelService.getInstance().clickReturnHome() }}>{t("return_home")}</Link>
                            </div>
                        </div>
                    :
                    <div className="btn-wrapper ">
                        <button className='btn active'>
                            <div className='lose-discount'>
                                <Link to={{ pathname: '/', search: props.location.search }} onClick={() => { LocalStorageService.clear(); MixpanelService.getInstance().clickReturnHome() }}>{t("return_home")}</Link>
                            </div>
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}