import axios from "axios";
import { removeDefaultHeaders, addAxiosDefaultHeaders } from '../../helpers'
export class DeliveryService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/payment/delivery_link`
    }
    showDeliveryData(accessToken) {
        removeDefaultHeaders(axios)
        const result = axios.get(`${this._baseUrl}/show`, {
            headers: {
                Authorization: `auth ${accessToken}`,
            }
        });
        addAxiosDefaultHeaders(axios)
        return result
    }
    submitDelivey(values, accessToken) {
        removeDefaultHeaders(axios)
        const result = axios.post(`${this._baseUrl}/submit`, values, {
            headers: {
                Authorization: `auth ${accessToken}`,
            }
        });
        addAxiosDefaultHeaders(axios)
        return result
    }
}