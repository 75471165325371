import React, { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from '../../../config/i18n';
import { languageEnum, benefitsThreshold, insuranceTypeEnum, linkFieldValue } from '../../../config/enums';
import True from './../../../assets/img/true.png';
import False from './../../../assets/img/false.png';
import descriptionHelp from './../../../assets/img/descriptionHelp.png'
import { Popup } from '../Modal';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
import { ServiceProxy } from '../../../ServiceProxy';
import * as Sentry from '@sentry/react';

export const PricingCard = ({ data, schema, confirmPackage, confirm, packageName, monthly, removeAnnuallyLabel, toggleSeeMore, handleToggleSeeMore, quotationId, ...props }) => {
    const { t } = useTranslation();
    const currentLang = getCurrentLanguage()
    const SMEMedicalInsurance = LocalStorageService.get(localStorageKeys.insuranceTypeID) === insuranceTypeEnum.SMEMedicalInsuranceId
    const employees = LocalStorageService.get(localStorageKeys.employeesNumber)
    const quotation_benefits_dict = currentLang === languageEnum.Arabic ?
        data.quotation_benefits_dict.AR : data.quotation_benefits_dict.EN;
    const dispalyWithComma = (value) => {
        return Math.ceil(Number(value)).toLocaleString()
    }
    const [visible, setVisible] = useState(false)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [isHovered, setIsHovered] = useState(false);
    const downloadFileRef = useRef(null);
    const isProgrammaticClick = useRef(false);
    const [fileLink, setFileLink] = useState(null)
    const handleHover = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleClickShowDesc = (data) => {
        setVisible(true)
        setTitle(data[0])
        setDescription(data[1])
        MixpanelService.getInstance().clickInfoIcon()
    }
    const Item = ({ index }) => {
        if (index < schema.length) {
            const field = quotation_benefits_dict[Object.keys(schema[index])[0]]
            return (
                <li>
                    <p>{Object.values(schema[index])[0]} {Object.values(schema[index])[1] ? <img alt='help icon' className='helpIcon' src={descriptionHelp}
                        onClick={() => handleClickShowDesc(Object.values(schema[index]))} /> : null}
                    </p>
                    {field === linkFieldValue ? <a href={fileLink} target="_blank" rel="noreferrer" onClick={(e) => handleDownloadAPI(e, data.id, Object.keys(schema[index])[0])} ref={downloadFileRef}>{t("click_here")}</a> :
                        <h3>{field === true ? <img src={True} alt="true" /> : field === false ? <img src={False} alt="false " /> : field}</h3>}
                </li>
            )
        }
        return null
    }
    const handleDownloadAPI = (event, id, type) => {
        const params = { file_type: type }
        MixpanelService.getInstance().clickMedicalNetwork({ price: data.gross_premium_pound, plan_name: packageName })
        if (event?.preventDefault) {
            if (isProgrammaticClick.current) {
                isProgrammaticClick.current = false;
                return;
            }
            event.preventDefault();
            ServiceProxy.LeadsService.getDownloadFileLink(quotationId, id, params)
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        setFileLink(res.data.data.file)
                        if (downloadFileRef.current) {
                            downloadFileRef.current.href = res.data.data.file;
                            isProgrammaticClick.current = true;
                            downloadFileRef.current.click();
                        }
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                })
        }
    }

    let benefits = null
    if (Object.values(quotation_benefits_dict).length <= benefitsThreshold) {
        benefits = (
            <div className="options">
                {Object.values(quotation_benefits_dict)?.map((_, index) => {
                    return (
                        <Item index={index} key={index} />
                    )
                })}
            </div>
        )
    }
    else if (Object.values(quotation_benefits_dict)?.length > benefitsThreshold && !toggleSeeMore) {
        benefits = (
            <div className="options">
                {Object.values(quotation_benefits_dict)?.slice(0, benefitsThreshold).map((_, index) => {
                    return (
                        <Item index={index} key={index} />
                    )
                })}
                <li />
                {/* <li onClick={handleToggleSeeMore}>
                    <h3 className='more'>{t("see_more")}</h3>
                </li> */}
            </div>
        )
    }
    else if (Object.values(quotation_benefits_dict)?.length > benefitsThreshold && toggleSeeMore) {
        benefits = (
            <div className="options">
                {Object.values(quotation_benefits_dict)?.map((_, index) => {
                    return (
                        <Item index={index} key={index} />
                    )
                })}
                <li onClick={handleToggleSeeMore}>
                    <h3 className='more'>{t("see_less")}</h3>
                </li>
            </div>
        )
    }
    return (
        <div className='parent-card' onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}>
            <div className="card" style={{ backgroundColor: props.background }} onClick={() => MixpanelService.getInstance().clickBenefitTable({ price: data.gross_premium_pound, plan_name: packageName })}>
                <div className="title">
                    <h2>{packageName}</h2>
                </div>
                {currentLang === languageEnum.Arabic ?
                    <div className="price">
                        {data.gross_premium_pound !== data.discounted_premium_pound ?
                            <>
                                <h5><del>{dispalyWithComma(data.gross_premium_pound)}</del>{`${data.currency_ar} `}</h5>
                                <h5>{`${dispalyWithComma(data.discounted_premium_pound)} ${data.currency_ar}`} <span>{t("paied_annually")}</span></h5>

                            </>
                            : <h5>{`${dispalyWithComma(data.gross_premium_pound)} ${data.currency_ar}`} {removeAnnuallyLabel ? undefined : <span>{t("paied_annually")}</span>}</h5>
                        }
                        {SMEMedicalInsurance ?
                            removeAnnuallyLabel ? undefined : <span>{`${dispalyWithComma(data.gross_premium_pound / employees)} ${data.currency_ar} ${t("employee")}`}</span>
                            :
                            removeAnnuallyLabel ? undefined : <span>{`${dispalyWithComma(data.gross_premium_pound / 12)} ${data.currency_ar} ${t("paied_monthly")}`}</span>
                        }
                    </div>
                    :
                    <div className="price">
                        {data.gross_premium_pound !== data.discounted_premium_pound ?
                            <>
                                <h5>{`${data.currency} `}<del>{dispalyWithComma(data.gross_premium_pound)}</del></h5>
                                <h5>{`${data.currency} ${dispalyWithComma(data.discounted_premium_pound)}`} <span>{t("paied_annually")}</span></h5>
                            </>
                            : <h5>{`${data.currency} ${dispalyWithComma(data.gross_premium_pound)}`} {removeAnnuallyLabel ? undefined : <span>{t("paied_annually")}</span>}</h5>
                        }
                        {SMEMedicalInsurance ?
                            removeAnnuallyLabel ? undefined : <span>{`${data.currency} ${dispalyWithComma(data.gross_premium_pound / employees)} ${t("employee")}`}</span>
                            :
                            removeAnnuallyLabel ? undefined : <span>{`${data.currency} ${dispalyWithComma(data.gross_premium_pound / 12)} ${t("paied_monthly")}`}</span>
                        }
                    </div>
                }
                {benefits}
                <button className="btn"
                    style={{ height: isHovered && '60px', }}
                    onClick={() => confirmPackage(data, packageName)}>{t("buy_policy")}</button>
                <Popup show={visible} title={title} description={description} onClick={() => setVisible(false)} />
            </div>
        </div>
    )
}