import React from 'react';
import { Modal } from '../../../components'
import { errorCodes, urls } from '../../../config/errorCodes'
import { LocalStorageService, localStorageKeys } from '../../../application/services';
import { amenliURLS } from '../../../config/enums';
export const withErrorHandler = (WrappedComponent, axios) => {
  class WithErrorHandler extends React.Component {
    state = {
      error: null,
      show: false,
      errorObj: null
    }
    componentDidMount() {
      this.reqInterceptor = axios.interceptors.request.use(request => {
        this.setState({ error: null });
        return request
      })
      this.resInterceptor = axios.interceptors.response.use(res => res, error => {
        if ((error.response.status === 400||error.response.status === 429) && urls.some(substring =>error.config.url.includes(substring))) {
          this.setState({ show: false })
        }
        else {
          this.setState({ error: error.response.data, show: true })
          if (error && error.response.data && error.response.data.code) {
            const result = errorCodes.find(item => item.code === error.response.data.code)
            this.setState({ errorObj: result })
          }
        }
     return error
      })
    }
    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor)
      axios.interceptors.response.eject(this.resInterceptor)
    }
    handleCloseModal = () => {
      this.setState({ show: false })
      const token = LocalStorageService.get(localStorageKeys.purchaseToken)
      if (token) {
        window.location.href = `${amenliURLS.Website}${window.location.search}`
      }
      else {
        this.props.history.push({ pathname: '/', search: this.props.location.search })
        window.location.reload()
      }
    }
    render() {
      const { errorObj } = this.state

      return (
        < div>
          <WrappedComponent {...this.props} />
          {this.state.error &&
            <Modal show={this.state.show} handleClose={this.handleCloseModal}
              message={errorObj && errorObj.message ? errorObj.message : ''} />

          }
        </ div>
      );
    }
  }
  return WithErrorHandler;
};

