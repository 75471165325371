import React from 'react'
import Avatar from '../../../assets/img/small_avatar.svg';
import { useTranslation } from 'react-i18next';
import { etisalatHost } from '../../../config/enums';
export const Greeting = () => {
    const { t } = useTranslation();
    return (
        <>
            {
                !etisalatHost && <div className="info">
                    <div className="user-img">
                        <img src={Avatar} alt="user" />
                    </div>
                    <div className="greeting">
                        <p>{t("encouragement_mesage")}</p>
                    </div>
                </div>
            }
        </>
    )
}