import React from 'react'
import { t } from 'i18next';
export function UIButton({
    title,
    disabled,
    loading,
    onClick,
    className,
    type
}) {
    return (
        <button
            disabled={disabled || loading}
            onClick={onClick}
            className={className}
            type={type}
        >
            {loading ? t("loading") : title}
        </button>
    )
}
