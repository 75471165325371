import React from 'react'
import { useTranslation } from 'react-i18next';

export const Heading = () => {
    const { t } = useTranslation();
    return (
        <div className="heading mt-45 mb-lg-4">
            <p>{t("ask_for_telling")}</p>
            <h3>{t("what_do_you_want")}</h3>
        </div>
    )
}