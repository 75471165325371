import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Stepper, withErrorHandler, Error, Greeting } from '../../../components';
import { DestinationCountry, HomeType, InsuranceProviders, CompanyNameField, CustomDOB, UsaCoverageInclusion, CarType } from '../components';
import { insuranceTypeEnum, policySteps } from '../../../config/enums';
import { localStorageKeys, LocalStorageService, MixpanelService } from '../../../application/services';

function StepOne(props) {
    const { t } = useTranslation();
    const activeStep = 1
    const [invalidRoute, setInvalidRoute] = useState(null)
    const [insuranceType, setInsuranceType] = useState(null)

    useEffect(() => {
        if (props.location.state?.insurance_type !== props.match.params?.type) {
            setInvalidRoute(true)
        }
        else {
            setInsuranceType(props.location.state?.insurance_type)
            MixpanelService.getInstance().qeStepOneViewed(props.location.state?.insurance_type)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const handleClickNext = () => {
        props.history.push({
            pathname: `/${props.location.state?.insurance_type}/step-two`, state: {
                insurance_type: props.location.state.insurance_type
            }, search: props.location.search
        })
    }

    return (
        <div className="container" id="page-wrapper">
            {!navigator.onLine ?
                <Error message={t("connection_error_message")} btnText={t("try_again_text")} onClick={() => window.location.reload()} /> :
                invalidRoute ? <Error message={t("invalid_path_message")} btnText={t("home_btn")} autoRedirect /> :
                    <div className="step pl-15 pr-15 wrapper">
                        <div className="head-wrapper">
                            <div>
                                <Greeting />
                                <Stepper activeStep={activeStep} numOfSteps={policySteps[insuranceType]} />
                            </div>
                        </div>
                        {insuranceType === insuranceTypeEnum.Life || insuranceType === insuranceTypeEnum.Medical ?
                            <CustomDOB handleClickNext={handleClickNext} title={t("nice_to_meet_you")} medical /> :
                            insuranceType === insuranceTypeEnum.Motor ? <CarType handleClickNext={handleClickNext} title={t("nice_to_meet_you")} /> :
                                insuranceType === insuranceTypeEnum.Travel ? <DestinationCountry handleClickNext={handleClickNext} title={t("nice_to_meet_you")} /> :
                                    insuranceType === insuranceTypeEnum.Home ? <HomeType handleClickNext={handleClickNext} title={t("nice_to_meet_you")} /> :
                                        insuranceType === insuranceTypeEnum.SMEMedical ? <CompanyNameField handleClickNext={handleClickNext} title={t("nice_to_meet_you")} /> :
                                            insuranceType === insuranceTypeEnum.InternationalMedical ? <UsaCoverageInclusion handleClickNext={handleClickNext} title={t("nice_to_meet_you")} /> :
                                                LocalStorageService.get(localStorageKeys.businessInsurance) ? <CompanyNameField handleClickNext={handleClickNext} title={t("nice_to_meet_you")} /> :
                                                    undefined}
                        {!LocalStorageService.get(localStorageKeys.businessInsurance) && <InsuranceProviders type={props.match.params?.type} />}
                    </div>
            }
        </div>
    )
}
export default withErrorHandler(StepOne, axios)