import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, TextField } from '../../../components/ui';
import { LocalStorageService, MixpanelService, localStorageKeys } from "../../../application/services";
import { validationRules } from "../../../config/validationRules";
import { convertArabic2EnNumbers } from '../../../helpers'
const Content_HOME_MIN_VALUE = validationRules.contentHomeValueMin;
const Content_HOME_MAX_VALUE = validationRules.contentHomeValueMax;

export function ContentValue(props) {
    const { t } = useTranslation();
    const [contentHomeValue, setContentHomeValue] = useState(LocalStorageService.get(localStorageKeys.contentHomeValue) || '')
    const [error, setError] = useState(undefined)
    const replaceNonNumeric = (numStr) => {
        return String(numStr).replace(/[^0-9\u0660-\u0669]/g, '')
    }
    const commarize = (numStr) => {
        return replaceNonNumeric(numStr).replace(/.(?=(...)*...$)/g, '$&,')
    }
    const handleChange = (e) => {
        const value = e.target.value
        var priceValue = commarize(value)
        setContentHomeValue(priceValue)
        const contentHomeValueToNumber = priceValue.replace(/,/g, "")
        if (isNaN(Number(convertArabic2EnNumbers(contentHomeValueToNumber)))) {
            setError({ ...error, price_invalid: t("validation_content_home_is_required_message") })
        }
        else if (convertArabic2EnNumbers(contentHomeValueToNumber) < Content_HOME_MIN_VALUE) {
            setError({ ...error, price_invalid: t("validation_content_home_minimum_length_message") })
        }
        else if (convertArabic2EnNumbers(contentHomeValueToNumber) > Content_HOME_MAX_VALUE) {

            setError({ ...error, price_invalid: t("validation_content_home_maximum_length_message") })

        }
        else if (!convertArabic2EnNumbers(contentHomeValueToNumber) > 0) {

            setError({ ...error, price_invalid: t("validation_content_home_value_message") })
        }
        else {
            setError(undefined)
        }
    }
    const handleNextStep = (e) => {
        e.preventDefault()
        if (!error) {
            contentHomeValue && props.handleClickNext()
            LocalStorageService.set(localStorageKeys.contentHomeValue, contentHomeValue);
            MixpanelService.getInstance().HomeValueFieldClickNext({ value: contentHomeValue })
        }
    }
    return (
        <div>
            <div className="heading mt-45">
                <p>{t("can_you_tell_me")} </p>
                <h3>{t("content_home_value_label")}</h3>
            </div>

            <div className="mt-30">
                <form onSubmit={handleNextStep} autoComplete="off" >
                    <TextField id="contentHomeValue"
                        placeholder={t("content_home_value_placeholder")}
                        value={contentHomeValue}
                        onChange={handleChange}
                        error={error && error.price_invalid ? true : false}
                        helperText={error && error.price_invalid}
                        name="contentHomeValue"
                        onFocus={() => MixpanelService.getInstance().clickHomeValueField()}
                    />
                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!contentHomeValue || error ? "disabled" : "active"}`}
                            title={t("next")}
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
