
import React, { useEffect, useState } from 'react';
import { Overlay, Spinner, TextField, withErrorHandler } from '../../components';
import { t } from 'i18next';
import * as Sentry from '@sentry/react';
import { useFormik } from 'formik';
import { deliveryFormValidationSchema } from '../quotationEngine/schema';
import axios from 'axios';
import { ServiceProxy } from '../../ServiceProxy';
import Logo from '../../assets/img/logoEN.svg';
import ExpandMoreBlack from '../../assets/img/arrow_down_black.svg';
import { Autocomplete } from '@mui/material';
const DeliveryLinks = (props) => {
    const token = new URLSearchParams(props.location.search).get("token")
    const [governorates, setGovernorates] = useState([])
    const [loading, setLoading] = useState(true)
    const [overlay, setOverlay] = useState(false)
    const [governorateValue, setGovernorateValue] = useState(null)
    const [cities, setCities] = useState([])
    const [cityValue, setCityValue] = useState(null)
    const [userData, setUserData] = useState(null)
    const getGovernorates = () => {
        ServiceProxy.UtilityService.getGovernorates()
            .then(response => {
                if (response.data) {
                    setGovernorates(response.data)
                    getDeliveryData()
                }
                else {
                    setLoading(false)
                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });
    }
    const getCities = (id) => {
        setOverlay(true)
        ServiceProxy.UtilityService.getCities(id)
            .then(response => {
                if (response.data) {
                    var cities = response.data
                    setCities(cities)
                    setOverlay(false)
                }
                else {
                    setOverlay(false)
                }
            })
            .catch(function (error) {
                setOverlay(false)
                Sentry.captureException(error);
            });
    }
    const getDeliveryData = () => {
        ServiceProxy.DeliveryService.showDeliveryData(token)
            .then(response => {
                setUserData(response.data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                Sentry.captureException(error);
            });
    }
    const submitDeliveryRequest = (data) => {
        setOverlay(true)
        ServiceProxy.DeliveryService.submitDelivey(data, token)
            .then(response => {
                props.history.replace('/success')
                setOverlay(false)
            })
            .catch(error => {
                setOverlay(false)
                Sentry.captureException(error);
            });
    }
    useEffect(() => {
        getGovernorates()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const formik = useFormik({
        initialValues: {
            city: '',
            building_number: '',
            apartment_number: '',
            floor: '',
            street: '',
            landmark: '',
            phone_number: userData ? userData.phone_number : '',
        },
        enableReinitialize: true,
        validationSchema: deliveryFormValidationSchema,
        onSubmit: (values) => {
            const data = { ...values, city: cityValue.id }
            submitDeliveryRequest(data)
        }
    });
    return (
        <div className="container" id="page-wrapper">
            {loading ? <Spinner /> :
                <div className="main-layout">
                    <h3 className='partner-title text-center mb-5'>{t("delivery_form_title")}</h3>
                    <div className='partner justify-content-center mb-5'>
                        <img style={{ width: "20%" }} src={Logo} alt="amenli logo" />
                    </div>
                    <b>{t("ahlan")}{`, ${userData && userData.full_name}`}</b>
                    <p className='opacity-75 mt-2'>{t("delivery_form_text")}</p>
                    {overlay && <Overlay />}
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <div className='mb-5 pt-4 d-flex row gap-4 delivey-form'>
                            <div className='row gap-4'>
                                <div className='col-12'>
                                    <Autocomplete
                                        options={governorates || []}
                                        getOptionLabel={(option) => option.name || null}
                                        popupIcon={<img src={ExpandMoreBlack} alt="expand" />}
                                        onChange={(event, value) => {
                                            setGovernorateValue(value)
                                            setTimeout(() => {
                                                value && getCities(value.id)
                                            }, 500);
                                        }}
                                        onClose={(event, reason, value) => {
                                            if (reason === 'selectOption') {
                                                setTimeout(() => {
                                                    value && getCities(value.id)
                                                }, 500);
                                            }
                                        }}
                                        value={governorateValue}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant={'outlined'}
                                                placeholder={t("governorate_name_placeholder")}
                                            />
                                        )}
                                        classes={{
                                            option: 'menuItem'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row gap-4'>
                                <div className='col-12'>
                                    <Autocomplete
                                        options={cities || []}
                                        getOptionLabel={(option) => option.name || null}
                                        popupIcon={<img src={ExpandMoreBlack} alt="expand" />}
                                        onChange={(event, value) => {
                                            setCityValue(value)

                                        }}
                                        value={cityValue}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant={'outlined'}
                                                placeholder={t("city_name_placeholder")}
                                            />
                                        )}
                                        classes={{
                                            option: 'menuItem'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row gap-4'>
                                <div className='col-12'>
                                    <TextField id="building_number"
                                        variant="outlined"
                                        placeholder={t("building_number_placeholder")}
                                        name="building_number"
                                        value={formik.values.building_number}
                                        inputProps={{ maxLength: 10 }}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.building_number &&
                                            Boolean(formik.errors.building_number)
                                        }
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {

                                                event.preventDefault()

                                            }
                                        }}
                                        helperText={
                                            formik.touched.building_number &&
                                            formik.errors.building_number
                                        }
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <TextField id="apartment_number"
                                        variant="outlined"
                                        placeholder={t("apartment_placeholder")}
                                        value={formik.values.apartment_number}
                                        inputProps={{ maxLength: 10 }}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.apartment_number &&
                                            Boolean(formik.errors.apartment_number)
                                        }
                                        helperText={
                                            formik.touched.apartment_number &&
                                            formik.errors.apartment_number
                                        }
                                        name="apartment_number"
                                    />
                                </div>
                                <div className='col-6'>
                                    <TextField id="floor"
                                        placeholder={t("floor_placeholder")}
                                        name="floor"
                                        variant="outlined"
                                        value={formik.values.floor}
                                        inputProps={{ maxLength: 10 }}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.floor &&
                                            Boolean(formik.errors.floor)
                                        }
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {

                                                event.preventDefault()

                                            }
                                        }}
                                        helperText={
                                            formik.touched.floor &&
                                            formik.errors.floor
                                        }
                                    />
                                </div>
                            </div>
                            <div className='row gap-4'>
                                <div className='col-12'>
                                    <TextField id="street"
                                        variant="outlined"
                                        placeholder={t("street_placeholder")}
                                        value={formik.values.street}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.street &&
                                            Boolean(formik.errors.street)
                                        }
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {

                                                event.preventDefault()

                                            }
                                        }}
                                        helperText={
                                            formik.touched.street &&
                                            formik.errors.street
                                        }
                                        name="street"
                                    />
                                </div>
                            </div>
                            <div className='row gap-4'>
                                <div className='col-12'>
                                    <TextField id="landmark"
                                        variant="outlined"
                                        placeholder={t("landmark_placeholder")}
                                        value={formik.values.landmark}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.landmark &&
                                            Boolean(formik.errors.landmark)
                                        }
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {

                                                event.preventDefault()

                                            }
                                        }}
                                        helperText={
                                            formik.touched.landmark &&
                                            formik.errors.landmark
                                        }
                                        name="landmark"
                                    />
                                </div>
                            </div>
                            <div className='row gap-4'>
                                <div className='col-12'>
                                    <TextField id="phone_number"
                                        variant="outlined"
                                        placeholder={t("phone_number_placeholder")}
                                        name="phone_number"
                                        type="tel"
                                        value={formik.values.phone_number}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.phone_number &&
                                            Boolean(formik.errors.phone_number)
                                        }
                                        helperText={
                                            formik.touched.phone_number &&
                                            formik.errors.phone_number
                                        }
                                    />
                                </div>
                            </div>
                            <div className='p-3 d-flex align-items-center justify-content-center'>
                                <p className='contact-us'>{t("contact_us_text")} <span>16887</span></p>
                            </div>
                            <div className='button-send'>
                                <button type='submit' className={`btn ${!cityValue || !formik.values.building_number || !formik.values.apartment_number || !formik.values.street || !formik.values.phone_number ? "disabled" : "active"}`}>
                                    {t('save_address')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}
export default withErrorHandler(DeliveryLinks, axios)