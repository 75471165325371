import React from 'react';
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from '../../../config/i18n';
import { languageEnum, amenliURLS } from '../../../config/enums';
import WhiteLogoAr from '../../../assets/img/whitelogoar.svg';
import WhiteLogoEn from '../../../assets/img/whitelogoen.svg';
import PaymobEn from '../../../assets/img/paymoben.png';
import PaymobAr from '../../../assets/img/paymobar.png';
import Valu from '../../../assets/img/valu.png'
import Facebook from '../../../assets/img/facebook.svg';
import Linkedin from '../../../assets/img/linkedin.svg';
import Instagram from '../../../assets/img/instagram.svg';
import Adva from '../../../assets/img/adva.png';
import Souhola from '../../../assets/img/souhola-logo.png';
export const Footer = () => {
    const { t } = useTranslation();
    const lang = getCurrentLanguage()
    const siteMapSection = [
        { title: t('home'), link: '' },
        { title: t('services'), link: '' },
        { title: t('process'), link: '' },
        { title: t('claims'), link: '' },
        { title: t('aboutus'), link: '' },
        { title: t('terms'), link: 'ourTerms' },
        { title: t('our_privacy'), link: 'ourPrivacy' }
    ];
    const renderSiteMapSection = siteMapSection.map((section, index) => <div className="menu-item" key={index}>
        <a href={`${amenliURLS.Website}${section.link}${window.location.search}`} target="_blank" rel="noreferrer">
            {section.title}
        </a>
    </div>)

    return (
        <footer className="section">
            <div className="section-content">
                <div className="row">
                    <div className="company-col col-12 col-sm-6 col-md-4 footer-col">
                        <img src={lang === languageEnum.Arabic ? WhiteLogoAr : WhiteLogoEn} alt="logo" width={'180px'} height={'51px'} />
                        <div className="menu-item">{t("join_us")}</div>
                        <a href="www.fra.gov.eg" className="escalate-link menu-item">www.fra.gov.eg</a>
                        <div className="empty-space"></div>
                        <div className="payment-logos row">
                            <div className="col-4 col-md-6 col-lg-4 logo-col">
                                <img src={lang === languageEnum.Arabic ? PaymobAr : PaymobEn} alt="logo" />
                            </div>
                            <div className="col-4 col-md-6 col-lg-4 logo-col">
                                <img src={Valu} alt="logo" />
                            </div>
                            <div className="col-4 col-md-6 col-lg-4 logo-col">
                                <img src={Adva} alt="logo" />
                            </div>
                            <div className="col-4 col-md-6 col-lg-4 logo-col">
                                <img src={Souhola} alt="logo" />
                            </div>
                        </div>

                    </div>
                    <div className="col-6 col-sm-6 col-md-2 footer-col">
                        <div className="head">{t('site_map')}</div>
                        {renderSiteMapSection}
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 footer-col">
                        <div className="head">{t('contact')}</div>
                        <div className="menu-item">16887</div>
                        <div className="menu-item">info@amenli.com</div>
                        <div className="mt-4 d-sm-none">
                            <div className="head">{t('address')}</div>
                            <div className="menu-item">{t('address_itself')}</div>
                            <div className="menu-item">{t('times')} </div>
                        </div>
                    </div>
                    <div className="d-none d-sm-block col-sm-6 col-md-3 footer-col">
                        <div className="head">{t('address')}</div>
                        <div className="menu-item">{t('address_itself')} </div>
                        <div className="menu-item">{t('times')} </div>

                    </div>
                </div>
                <div className="row py-5">
                    <div className="center-min menu-item footer-col col-12 col-md-3 social-container">
                        <a className="social-media-icon-container" href={amenliURLS.Instagram}><img className="social-media-icon" src={Instagram} alt="Instagram link" /></a>
                        <a className="social-media-icon-container" href={amenliURLS.Facebook}><img className="social-media-icon" src={Facebook} alt="Facebook link" /></a>
                        <a className="social-media-icon-container" href={amenliURLS.Linkedin}><img className="social-media-icon" src={Linkedin} alt="Linkedin link" /></a>
                    </div>
                    <div className="center-min menu-item footer-col col-12 col-md-6 align-center">{t('approved')}</div>
                    <div className="center-min menu-item footer-col col-12 col-md-3 align-end">© {new Date().getFullYear()} {t('amenli')}</div>
                </div>
            </div>
        </footer>
    )
}