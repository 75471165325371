import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRadioButton } from '../../../components/ui';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
import { carTypeEnum } from '../../../config/enums';


export function CarType(props) {
    const { t } = useTranslation();
    const [carType, setCarType] = useState(LocalStorageService.get(localStorageKeys.carType))
    const handleChangeCarType = (e) => {
        setCarType(e.target.value)
        if (e.target.value) {
            LocalStorageService.set(localStorageKeys.carType, e.target.value);
            MixpanelService.getInstance().selectCarType({ type: e.target.value })
        }
        setTimeout(() => {
            props.handleClickNext()
        }, 500);
    }
    return (
        <div>
            <div className="heading mt-45 ">
                <p>{props.title ? props.title : t("can_you_tell_me")} </p>
                <h3>{t("car_type_label")}</h3>
            </div>
            <div className="row">
                <div className="col-12 col-md">
                    <CustomRadioButton label={t("car_type_electric")}
                        id="electric"
                        value="electric"
                        name="carType"
                        onChange={handleChangeCarType}
                        checked={carType === carTypeEnum.Electric}
                        onClick={handleChangeCarType}
                    />
                </div>
                <div className="col-12 col-md">
                    <CustomRadioButton label={t("car_type_fuel")}
                        id="fuel"
                        value="fuel"
                        name="carType"
                        onChange={handleChangeCarType}
                        checked={carType === carTypeEnum.Fuel}
                        onClick={handleChangeCarType}
                    />
                </div>
            </div>
        </div>
    )
}
