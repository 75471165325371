import React from 'react';
import { useTranslation } from "react-i18next";
import { CheckoutCard } from '../../../components/ui';
import FullPayment from '../../../assets/img/full_amount.svg';
import InstallmentsPayment from '../../../assets/img/installment.svg';
import { paymentTypeEnum } from '../../../config/enums';

export function PaymentTypes({
    value,
    ChangePaymentType,
    paymentMethods
}) {
    const { t } = useTranslation();
    return (
        <div className="payment-wrapper">
            <h3>{t("payment_type_title")}</h3>
            <div className="cards-wrapper">
                {paymentMethods.full_payment && paymentMethods.full_payment.length > 0 ?
                    <CheckoutCard
                        title={t("full_amount")}
                        icon={FullPayment}
                        selected={value === paymentTypeEnum.FullAmount}
                        onClick={() => ChangePaymentType(paymentTypeEnum.FullAmount)}
                    />
                    : undefined}
                {paymentMethods.instalments && paymentMethods.instalments.length > 0 ?
                    <CheckoutCard
                        title={t("installments")}
                        icon={InstallmentsPayment}
                        selected={value === paymentTypeEnum.Installments}
                        onClick={() => ChangePaymentType(paymentTypeEnum.Installments)}
                    />
                    : undefined}
            </div>
        </div>
    )
}
