import { LocalStorageService, localStorageKeys } from '../application/services';
import { amenliProducts, homeTypeEnum, insuranceTypeEnum, isChronicIllnessEnum, usaCoverageEnum } from '../config/enums';
import { validationRules } from '../config/validationRules';


export const convertPriceToCents = (value) => {
    return Number((value).replace(/,/g, '') * 100).toFixed();
};
export const splitParams = (query) => {
    var paramsList = query.replace("?", "").split("&");
    var paramsDict = paramsList.reduce((r, e) => {
        (r[e.split('=')[0]] = decodeURIComponent(e.slice(e.indexOf('=') + 1)))
        return r;
    }, {});
    return paramsDict;
}
export const convertArabic2EnNumbers = (input) => {
    var result = input.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) { return d.charCodeAt(0) - 1632; })
        .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) { return d.charCodeAt(0) - 1776; });
    return result;
}
export const setQuotationData = () => {
    const queryParams = new URLSearchParams(window.location.search);
    var quotationData = {
        utm_code: LocalStorageService.get(localStorageKeys.quotationSource) || queryParams.get('src') || "",
        promo_code: LocalStorageService.get(localStorageKeys.promoCode) || queryParams.get('prm') || "",
        name: LocalStorageService.get(localStorageKeys.userName),
        phone_number: convertArabic2EnNumbers(LocalStorageService.get(localStorageKeys.userPhoneNumber)),
        age: LocalStorageService.get(localStorageKeys.userAge),
        date_of_birth: LocalStorageService.get(localStorageKeys.dateOfBirth),
        insurance_type: Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)),
        is_app: LocalStorageService.get(localStorageKeys.app) ? true : false,
    }
    if (LocalStorageService.get(localStorageKeys.etisalatExternalReference) || queryParams.get('external_reference')) {
        quotationData = {
            ...quotationData,
            external_reference: LocalStorageService.get(localStorageKeys.etisalatExternalReference) || queryParams.get('external_reference')

        }
    }
    if (Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)) === insuranceTypeEnum.MedicalInsuranceId) {
        const hasChronicIllness = LocalStorageService.get(localStorageKeys.userHasCronicalIllness)
        quotationData = {
            ...quotationData,
            gender: LocalStorageService.get(localStorageKeys.userGender),
            is_chronic_illness: hasChronicIllness ? hasChronicIllness : isChronicIllnessEnum.False,
        }
    }
    else if (Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)) === insuranceTypeEnum.LifeInsuranceId) {
        quotationData = {
            ...quotationData,
            reason: LocalStorageService.get(localStorageKeys.lifeInsuranceGoal),
        }
    }
    else if (Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)) === insuranceTypeEnum.MotorInsuranceId) {
        quotationData = {
            ...quotationData,
            motor_price_egp: Number(convertArabic2EnNumbers(LocalStorageService.get(localStorageKeys.motorPrice).replace(/,/g, ''))),
            motor_model_year: Number(LocalStorageService.get(localStorageKeys.motorYear)),
            motor_brand_id: LocalStorageService.get(localStorageKeys.motorBrand),
        }
        if (LocalStorageService.get(localStorageKeys.motorModel)) {
            quotationData = {
                ...quotationData,
                motor_model_id: LocalStorageService.get(localStorageKeys.motorModel),
            }
        }
    }
    else if (Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)) === insuranceTypeEnum.TravelInsuranceId) {
        quotationData = {
            ...quotationData,
            destination: LocalStorageService.get(localStorageKeys.country),
            travel_date: LocalStorageService.get(localStorageKeys.travelDate),
            return_date: LocalStorageService.get(localStorageKeys.returnDate),
            passport_number: LocalStorageService.get(localStorageKeys.passportNumber)
        }
    }
    else if (Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)) === insuranceTypeEnum.HomeInsuranceId) {
        quotationData = {
            ...quotationData,
            ownership_type: LocalStorageService.get(localStorageKeys.homeType),
            city_id: LocalStorageService.get(localStorageKeys.city),
            address: LocalStorageService.get(localStorageKeys.streetName),
            building: LocalStorageService.get(localStorageKeys.buildingNumber) ? String(LocalStorageService.get(localStorageKeys.buildingNumber)) : null,
            floor: LocalStorageService.get(localStorageKeys.floorNumber) ? String(LocalStorageService.get(localStorageKeys.floorNumber)) : null,
            apartment: LocalStorageService.get(localStorageKeys.apartment) ? String(LocalStorageService.get(localStorageKeys.apartment)) : null,
        }
        if (LocalStorageService.get(localStorageKeys.homeType) === homeTypeEnum.Owner) {
            quotationData = {
                ...quotationData,
                home_value: Number(convertArabic2EnNumbers(LocalStorageService.get(localStorageKeys.homeValue).replace(/,/g, ''))),
            }
        }
        if (LocalStorageService.get(localStorageKeys.homeType) === homeTypeEnum.Tenant) {
            quotationData = {
                ...quotationData,
                content_value: Number(convertArabic2EnNumbers(LocalStorageService.get(localStorageKeys.contentHomeValue).replace(/,/g, ''))),
            }
        }
    }
    else if (Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)) === insuranceTypeEnum.SMEMedicalInsuranceId) {
        quotationData = {
            ...quotationData,
            company_name: LocalStorageService.get(localStorageKeys.companyName),
            number_of_employees: LocalStorageService.get(localStorageKeys.employeesNumber),
            average_ages: LocalStorageService.get(localStorageKeys.employeesAge),
        }
        delete quotationData.age
        delete quotationData.date_of_birth
    }
    else if (LocalStorageService.get(localStorageKeys.businessInsurance)) {
        quotationData = {
            ...quotationData,
            company_name: LocalStorageService.get(localStorageKeys.companyName)
        }
        delete quotationData.age
        delete quotationData.date_of_birth
    }
    else if (Number(LocalStorageService.get(localStorageKeys.insuranceTypeID)) === insuranceTypeEnum.InternationalMedicalId) {
        const usaCoverage = LocalStorageService.get(localStorageKeys.usaCoverage)
        const hasChronicIllness = LocalStorageService.get(localStorageKeys.userHasCronicalIllness)
        quotationData = {
            ...quotationData,
            is_usa_covered: usaCoverage === "true" ? usaCoverageEnum.True : usaCoverageEnum.False,
            is_chronic_illness: hasChronicIllness ? hasChronicIllness : isChronicIllnessEnum.False,
            gender: LocalStorageService.get(localStorageKeys.userGender)
        }
    }
    return quotationData;
}

export const calculateAge = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    LocalStorageService.set(localStorageKeys.userAge, age_now)
    return age_now;
}

export const isValidURL = (str) => {
    var pattern = new RegExp(validationRules.URLRegex)
    return pattern.test(str);
};
export const getProductType = (insuranceType) => {
    const type = amenliProducts.find(element => element.type === insuranceType)
    if (type) {
        return type.product
    }
    return type
}


