import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Button, TextField } from '../../../components/ui';
import { passportNumberValidationSchema } from '../schema';
import { LocalStorageService, localStorageKeys } from '../../../application/services';
export function PassportNumber(props) {
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            passportNumber: LocalStorageService.get(localStorageKeys.passportNumber) || '',
        },
        validationSchema: passportNumberValidationSchema,
        onSubmit: (values) => {
            props.handleClickNext()
            LocalStorageService.set(localStorageKeys.passportNumber, values.passportNumber);
        },
    });

    return (
        <div>
            <div className="heading mt-45">
                <p>{props.title ? props.title : t("can_you_tell_me")} </p>
                <h3>{t("passport_number_label")}</h3>
            </div>
            <div className="mt-30">
                <form onSubmit={formik.handleSubmit} autoComplete="off" >
                    <TextField id="passportNumber"
                        placeholder={t("passport_number_placeholder")}
                        name="passportNumber"
                        value={formik.values.passportNumber}
                        onChange={
                            formik.handleChange}
                        error={
                            formik.touched.passportNumber &&
                            Boolean(formik.errors.passportNumber)
                        }
                        helperText={
                            formik.touched.passportNumber &&
                            formik.errors.passportNumber
                        }
                    />

                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!formik.values.passportNumber ? "disabled" : "active"}`}
                            title={t("next")}
                            disabled={!formik.values.passportNumber}
                            type="submit"
                        />
                    </div>


                </form>
            </div>
        </div >
    )
}
