import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Button, Spinner } from '../../../components/ui';
import { LocalStorageService, localStorageKeys, MixpanelService } from "../../../application/services";
import { ServiceProxy } from '../../../ServiceProxy';
import ExpandMore from '../../../assets/img/arrow_down.svg';
import ExpandMoreBlack from '../../../assets/img/arrow_down_black.svg'
import { carTypeEnum, etisalatHost } from '../../../config/enums';
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export function CarModels(props) {
	const { t } = useTranslation();
	const [model, setModel] = useState(null)
	const [carModels, setCarModels] = useState([])
	const [modelValue, setModelValue] = useState(null)
	const [loading, setLoading] = useState(true)
	const brandName = LocalStorageService.get(localStorageKeys.motorBrand)
	const isQuestionExplanation = useFeatureIsOn("question-explanation")
	const handleClickNext = () => {
		props.handleClickNext()
		LocalStorageService.set(localStorageKeys.motorModel, modelValue.id);
		MixpanelService.getInstance().carModelFieldClickNext({ car_model_name: modelValue.name, car_model_id: modelValue.id })

	}
	useEffect(() => {
		const params = {
			is_electric: LocalStorageService.get(localStorageKeys.carType) === carTypeEnum.Electric ? true : false,
			brand_id: brandName
		}
		ServiceProxy.UtilityService.getCarModels(params)
			.then(response => {
				setLoading(false)
				if (response.data && response.data.length > 0) {
					setCarModels(response.data)
					const carModel = LocalStorageService.get(localStorageKeys.motorModel)
					setModelValue(carModel ? response.data.find(item => item.id === carModel) : null)
					setModel(carModel)
				}
				else {
					props.handleSkipStep()
				}
			})
			.catch(function (error) {
				setLoading(false)
				Sentry.captureException(error);
			});
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div>
			{loading ? <Spinner /> :
				<>
					<div className="heading mt-45 mb-30">
						<p>{props.title ? props.title : t("can_you_tell_me")}</p>
						<h3>{t("car_model_label")}</h3>
						{isQuestionExplanation && <h4>{t("car_model_explanation")}</h4>}
					</div>
					<Autocomplete
						options={carModels || []}
						getOptionLabel={(option) => option.name || null}
						popupIcon={<img src={etisalatHost ? ExpandMoreBlack : ExpandMore} alt="expand" />}
						onChange={(event, value, reason) => {
							setModelValue(value)
							if (reason === 'clear')
								MixpanelService.getInstance().clickXButton()
							else
								MixpanelService.getInstance().selectCarModel({ car_model: value.name, car_model_id: value.id })
						}}
						value={modelValue}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={t("car_model_placeholder")}
								variant={etisalatHost ? 'outlined' : 'standard'}
								onFocus={() => MixpanelService.getInstance().clickCarModelField()}
							/>
						)}
						classes={{
							option: etisalatHost ? 'menuItem' : null
						}}
					/>
					<div className="btn-wrapper">
						<Button
							className={`btn ${!model && !modelValue ? "disabled" : "active"}`}
							title={t("next")}
							disabled={!model && !modelValue}
							type="submit"
							onClick={handleClickNext}
						/>
					</div>
				</>
			}
		</div>
	)
}