import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import * as Sentry from '@sentry/react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { Button, Spinner } from '../../../components/ui';
import { LocalStorageService, MixpanelService, localStorageKeys } from "../../../application/services";
import { ServiceProxy } from '../../../ServiceProxy';
import ExpandMore from '../../../assets/img/arrow_down.svg'
import ExpandMoreBlack from '../../../assets/img/arrow_down_black.svg'
import { carTypeEnum, etisalatHost } from '../../../config/enums';
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export function CarBrandField(props) {
    const { t } = useTranslation();
    const [brand, setBrand] = useState(null)
    const [brandValue, setBrandValue] = useState(null)
    const [motorBrands, setMotorBrands] = useState([])
    const [loading, setLoading] = useState(true)
    const isQuestionExplanation = useFeatureIsOn("question-explanation")
    const handleClickNext = () => {
        props.handleClickNext()
        LocalStorageService.set(localStorageKeys.motorBrand, brandValue.id);
        LocalStorageService.set(localStorageKeys.motorModel, '');
        MixpanelService.getInstance().carsFieldClickNext({ car_name: brandValue.name, car_id: brandValue.id })

    }
    useEffect(() => {
        const params = {
            is_electric: LocalStorageService.get(localStorageKeys.carType) === carTypeEnum.Electric ? true : false
        }
        ServiceProxy.UtilityService.getBrands(params)
            .then(response => {
                setLoading(false)
                if (response.data && response.data.length > 0) {
                    setMotorBrands(response.data)
                    const carBrand = LocalStorageService.get(localStorageKeys.motorBrand)
                    setBrandValue(carBrand ? response.data.find(item => item.id === carBrand) : null)
                    setBrand(carBrand)
                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div>
            {loading ? <Spinner /> :
                <>
                    <div className="heading mt-45 mb-30">
                        <p>{props.title ? props.title : t("can_you_tell_me")}</p>
                        <h3>{t("car_brand_label")}</h3>
                        {isQuestionExplanation && <h4>{t("car_brand_explanation")}</h4>}
                    </div>
                    <Autocomplete
                        options={motorBrands || []}
                        getOptionLabel={(option) => option.name || null}
                        popupIcon={<img src={etisalatHost ? ExpandMoreBlack : ExpandMore} alt="expand" />}
                        onChange={(event, value, reason) => {
                            setBrandValue(value)
                            if (reason === 'clear')
                                MixpanelService.getInstance().clickXButton()
                            else
                                MixpanelService.getInstance().selectCar({ car_name: value.name, car_id: value.id })
                        }}
                        value={brandValue}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant={etisalatHost ? 'outlined' : 'standard'}
                                placeholder={t("car_brand_placeholder")}
                                onFocus={() => MixpanelService.getInstance().clickCarsField()}
                            />
                        )}
                        classes={{
                            option: etisalatHost ? 'menuItem' : null
                        }}
                    />
                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!brand && !brandValue ? "disabled" : "active"}`}
                            title={t("next")}
                            disabled={!brand && !brandValue}
                            type="submit"
                            onClick={handleClickNext}
                        />
                    </div>
                </>
            }
        </div>
    )
}
