import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { LocalStorageService, localStorageKeys } from "../../../application/services";
import { Spinner } from '../../../components';
import { amenliURLS } from '../../../config/enums';

export function KioskCode(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const token = LocalStorageService.get(localStorageKeys.purchaseToken)
    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }, [])

    return (
        <div className="container" id="page-wrapper">
            {loading ? <Spinner /> :
                <div className='kiosk-code-wrapper wrapper'>
                    <div className='text-center'>
                        <p className='body-20 mb-0'>{t("kiosk_code_title")}</p>
                        <p className='heading mb-50'>{props.location && props.location.state.code}</p>
                    </div>

                    <div className='how-to-pay mt-50'>
                        <p className='body-20 bold mb-10'>{t("how")}</p>
                        <p className='body-16'>{t("kiosk_guide")}</p>
                    </div>
                    <div className="btn-wrapper static">
                        {token ?
                            <a href={`${amenliURLS.Website}${window.location.search}`}><button className="btn-purchase upload-now" onClick={() => LocalStorageService.clear()}>{t("return_home")}</button></a>
                            :
                            <Link to={{ pathname: '/', search: props.location.search }}><button className="btn-purchase upload-now" onClick={() => LocalStorageService.clear()}>{t("return_home")}</button></Link>
                        }
                    </div>
                </div>
            }

        </div>

    )
}
