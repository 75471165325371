import React, { useEffect } from "react";
import detect from "detect.js";
export const AppLink = () => {
	const handleAppLink = () => {
		var ua = detect.parse(navigator.userAgent);
		if (ua.os && ua.os.family) {
			if (ua.os.family === 'Android') {
				window.location.href = `${process.env.REACT_APP_ANDROID}&${window.location.search.replace('utm', 'utm_campaign')}`
			}
			else if (ua.os.family === 'iOS') {
				window.location.href = `${process.env.REACT_APP_IOS}${window.location.search.replace('utm', 'ct')}`
			}
			else {
				window.location.href = `/${window.location.search}`
			}
		}
		else {
			window.location.href = `/${window.location.search}`
		}
	}
	useEffect(() => {
		handleAppLink()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container" id="page-wrapper" />
	)
}