import Avatar from '../../../assets/img/medium_avatar.svg'
import React from 'react'
import { WaitData } from '../../ui'
export function LoadingPackages({
    hasImage,
    subHeading,
    heading
}) {
    return (
        <div className="loading-packages">
            <WaitData />
            <div className={hasImage?"d-flex justify-content-center":'text-center'}>
                {hasImage &&
                    <div className="user-img">
                        <img src={Avatar} alt="Amenli" />
                    </div>
                }
                {subHeading&&<p className='subHeading'>{subHeading}</p>}
                <p className='heading'>{heading}</p>
            </div>
        </div>
    )
}