import React from 'react'

export function CheckoutCard({
    title,
    onClick,
    selected,
    smallCard,
    icon,
    className,
    vfIcon
}) {
    return (
        <div className="checkout-card">
            <button className={`card-btn ${selected ? "selected-btn" : ''} ${smallCard ? "small-card" : ''} ${vfIcon ? "text-center pl-0 pr-0" : ''}${className ? className : ''}`}
                onClick={onClick}>
                {icon && <img src={icon} alt="cardIcon" className={vfIcon ? 'vf-cash' : ''} />}
                {title}
            </button>
        </div>
    )
}
