import axios from "axios";
export class PartnerLeadFormService {
	constructor(_baseUrl) {
		this._baseUrl = `${_baseUrl}/leads/partner-forms`
	}
	getForm(form_uuid) {
		return axios.get(`${this._baseUrl}/${form_uuid}/inquiry`);
	}
	submitForm(form_uuid, values) {
		return axios.post(`${this._baseUrl}/${form_uuid}/submit`, values);
	}
}