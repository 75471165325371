import { ServiceProxy } from '../../ServiceProxy';
const emptyLookups = {
  life: [],
  motor: [],

};
export class LookupService {
  static _instance;
  lookups = emptyLookups;
  static async getInstance() {
    if (!LookupService._instance) {
      LookupService._instance = new LookupService();
      LookupService._instance.lookups =
        (await LookupService.init());
    }
    // console.log("LOOKUPS", LookupService._instance);
    return LookupService._instance;
  }

  static init() {
    return ServiceProxy.InsuranceService.loadAll()
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return emptyLookups;
      });
  }

}