
import * as Yup from "yup";
import i18next from "i18next";
import { validationRules } from "../../config/validationRules";
import differenceInYears from "date-fns/differenceInYears";
import { convertArabic2EnNumbers } from "../../helpers";
const MOBILE_REGEX = new RegExp(validationRules.mobileRegex)
const NAME_REGEX = new RegExp(validationRules.nameRegex);
const MOBILE_LENGTH = validationRules.mobileLength;
const NAME_MIN_LENGTH = validationRules.nameMinLength;
const NAME_MAX_LENGTH = validationRules.nameMaxLength;
const AGE_MAX = validationRules.ageMaximum;
const CAR_AGE_MIN = validationRules.carMinAge;
const FLOOR_MAX_LENGTH = validationRules.floorMaxLength;
const FLOOR_MIN_LENGTH = validationRules.floorMinLength;
const BUILDING_MIN_LENGTH = validationRules.builbdingMinLength;
const BUILDING_MAX_LENGTH = validationRules.builbdingMaxLength;
const ADDRESS_MAX_LENGTH = validationRules.addressMaxLength;
const ADDRESS_MIN_LENGTH = validationRules.addressMinLength;
const EMPLOYEES_MAX = validationRules.maxEmployeesNumber;
const EMPLOYEES_MIN = validationRules.minEmployeesNumber;
const PARTNER_LEAD_FORM_NAME_MIN_Length = validationRules.partnerLeadFormNameMinLength;
const currentYear = new Date().getFullYear();

export const nameValidationSchema = Yup.object({
    name: Yup
        .string()
        .trim()
        .min(
            NAME_MIN_LENGTH,
            i18next.t("validation_name_minimum_length_message", {
                length: NAME_MIN_LENGTH,
            })
        )
        .max(
            NAME_MAX_LENGTH,
            i18next.t("validation_name_maximum_length_message", {
                length: NAME_MAX_LENGTH,
            })
        )
        .matches(NAME_REGEX, i18next.t("validation_name_format_message"))
        .required(i18next.t("validation_name_is_required_message")),
});
export const passportNameValidationSchema = Yup.object({
    name: Yup
        .string()
        .min(
            NAME_MIN_LENGTH,
            i18next.t("validation_name_minimum_length_message", {
                length: NAME_MIN_LENGTH,
            })
        )
        .max(
            NAME_MAX_LENGTH,
            i18next.t("validation_name_maximum_length_message", {
                length: NAME_MAX_LENGTH,
            })
        )
        .matches(NAME_REGEX, i18next.t("validation_name_format_message"))
        .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, (i18next.t("validation_full_name_message")))
        .required(i18next.t("validation_name_is_required_message")),
});
export const phoneNumberValidationSchema = Yup.object({
    phoneNumber: Yup
        .string()
        .required(i18next.t("validation_phone_is_required_message"))
        .max(
            MOBILE_LENGTH,
            i18next.t("validation_phone_length_message", { length: MOBILE_LENGTH })
        )
        .matches(MOBILE_REGEX, i18next.t("validation_phone_format_message")),
});
export const addressValidationSchema = Yup.object({
    address: Yup
        .string()
        .trim()
        .min(
            ADDRESS_MIN_LENGTH,
            i18next.t("validation_address_minimum_length_message", {
            })
        )
        .max(
            ADDRESS_MAX_LENGTH,
            i18next.t("validation_address_maximum_length_message", {
            })
        )
        .required(i18next.t("validation_address_is_required_message")),
    buildingNumber: Yup
        .string()
        .trim()
        .min(
            BUILDING_MIN_LENGTH,
            i18next.t("validation_building_minimum_length_message")
        )
        .max(
            BUILDING_MAX_LENGTH,
            i18next.t("validation_building_maximum_length_message")
        )
        .required(i18next.t("validation_building_is_required_message")),

    apartment: Yup
        .number()
        .min(
            FLOOR_MIN_LENGTH,
            i18next.t("apartment_minimum_length_message")
        )
        .max(
            FLOOR_MAX_LENGTH,
            i18next.t("apartment_maximum_length_message")
        )
        .required(i18next.t("apartment_is_required_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .integer(i18next.t("validation_number_error_message")),

    floorNumber: Yup
        .number()
        .min(
            FLOOR_MIN_LENGTH,
            i18next.t("validation_floor_minimum_length_message")
        )
        .max(
            FLOOR_MAX_LENGTH,
            i18next.t("validation_floor_maximum_length_message")
        )
        .required(i18next.t("validation_floor_is_required_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .integer(i18next.t("validation_number_error_message")),
});
export const passportNumberValidationSchema = Yup.object({
    passportNumber: Yup
        .string()
        //.trim()
        //.integer(passoprt_number_format_message)
        .required(i18next.t("passport_number_is_required_message")),
});
export const ageValidationSchema = Yup.object({
    age: Yup
        .number()
        .required(i18next.t("validation_age_is_required_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .max(
            AGE_MAX,
            i18next.t("validation_max_age_message", { max: AGE_MAX })
        )
        .positive(i18next.t("validation_age_format_message"))
        .integer(i18next.t("validation_age_format_message")),
});
export const birthDateValidationSchema = Yup.object({
    dob: Yup.date()
        .nullable()
        .test("dob", "Should be greater than 18", function (value) {
            return differenceInYears(new Date(), new Date(value)) >= 18;
        }),
});
export const carAgeValidationSchema = Yup.object({
    age: Yup
        .number()
        .required(i18next.t("validation_age_is_required_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .max(
            AGE_MAX,
            i18next.t("validation_max_age_message", { max: AGE_MAX })
        )
        .min(
            CAR_AGE_MIN,
            i18next.t("validation_min_age_message", { min: CAR_AGE_MIN })
        )
        .integer(i18next.t("validation_age_format_message")),
});
export const dobValidationSchema = Yup.object({
    day: Yup
        .number()
        .transform((_, originalvalue) => {
            return Number(convertArabic2EnNumbers(String(originalvalue)));
        })
        .min(1, i18next.t("validation_number_error_message"))
        .max(31, i18next.t("validation_number_error_message"))
        .required(i18next.t("validation_number_error_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .integer(i18next.t("validation_number_error_message")),
    month: Yup
        .number()
        .transform((_, originalvalue) => {
            return Number(convertArabic2EnNumbers(String(originalvalue)));
        })
        .min(1, i18next.t("validation_number_error_message"))
        .max(12, i18next.t("validation_number_error_message"))
        .required(i18next.t("validation_number_error_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .integer(i18next.t("validation_number_error_message")),
    year: Yup
        .number()
        .transform((_, originalvalue) => {
            return Number(convertArabic2EnNumbers(String(originalvalue)));
        })
        .max(currentYear, i18next.t("validation_number_error_message"))
        .required(i18next.t("validation_number_error_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .integer(i18next.t("validation_number_error_message")),
});
export const companyNameValidationSchema = Yup.object({
    companyName: Yup
        .string()
        .trim()
        .max(
            NAME_MAX_LENGTH,
            i18next.t("validation_name_maximum_length_message", {
                length: NAME_MAX_LENGTH,
            })
        )
        .matches(NAME_REGEX, i18next.t("validation_name_format_message"))
        .required(i18next.t("validation_company_name_is_required_message")),
});
export const employeesNumberValidationSchema = Yup.object({
    employeesNumber: Yup
        .number()
        .required(i18next.t("validation_employees_num_is_required_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .min(
            EMPLOYEES_MIN,
            i18next.t("validation_min_employees_message", { min: EMPLOYEES_MIN })
        )
        .max(
            EMPLOYEES_MAX,
            i18next.t("validation_max_employees_message", { max: EMPLOYEES_MAX })
        )
        .integer(i18next.t("validation_number_error_message")),
});
export const PartnerLeadFormSchema = Yup.object({
    full_name: Yup
        .string()
        .min(
            PARTNER_LEAD_FORM_NAME_MIN_Length,
            i18next.t("validation_name_minimum_length_message", {
                length: PARTNER_LEAD_FORM_NAME_MIN_Length,
            })
        )
        .max(
            NAME_MAX_LENGTH,
            i18next.t("validation_name_maximum_length_message", {
                length: NAME_MAX_LENGTH,
            })
        )
        .matches(/^(?:[\u0600-\u06FFA-Za-z]{2,}\s?)+$/, (i18next.t("validation_partner_name_format_message")))
        .required(i18next.t("validation_name_is_required_message")),

    phone_number: Yup
        .string()
        .required(i18next.t("validation_phone_is_required_message"))
        .max(
            MOBILE_LENGTH,
            i18next.t("validation_phone_length_message", { length: MOBILE_LENGTH })
        )
        .matches(MOBILE_REGEX, i18next.t("validation_phone_format_message")),
});
export const deliveryFormValidationSchema = Yup.object({
    street: Yup
        .string()
        .trim()
        .min(
            ADDRESS_MIN_LENGTH,
            i18next.t("validation_address_minimum_length_message", {
            })
        )
        .max(
            ADDRESS_MAX_LENGTH,
            i18next.t("validation_address_maximum_length_message", {
            })
        )
        .required(i18next.t("validation_address_is_required_message")),
    landmark: Yup
        .string()
        .nullable()
        .trim()
        .min(
            ADDRESS_MIN_LENGTH,
            i18next.t("validation_address_minimum_length_message", {
            })
        )
        .max(
            ADDRESS_MAX_LENGTH,
            i18next.t("validation_address_maximum_length_message", {
            })
        ),

    building_number: Yup
        .string()
        .trim()
        .min(
            BUILDING_MIN_LENGTH,
            i18next.t("validation_building_minimum_length_message")
        )
        .max(
            BUILDING_MAX_LENGTH,
            i18next.t("validation_building_maximum_length_message")
        )
        .required(i18next.t("validation_building_is_required_message")),

    apartment_number: Yup
        .number()
        .min(
            FLOOR_MIN_LENGTH,
            i18next.t("apartment_minimum_length_message")
        )
        .max(
            FLOOR_MAX_LENGTH,
            i18next.t("apartment_maximum_length_message")
        )
        .required(i18next.t("apartment_is_required_message"))
        .typeError(i18next.t("validation_number_error_message"))
        .integer(i18next.t("validation_number_error_message")),

    floor: Yup
        .number()
        .min(
            FLOOR_MIN_LENGTH,
            i18next.t("validation_floor_minimum_length_message")
        )
        .max(
            FLOOR_MAX_LENGTH,
            i18next.t("validation_floor_maximum_length_message")
        )
        .typeError(i18next.t("validation_number_error_message"))
        .integer(i18next.t("validation_number_error_message")),
    phone_number: Yup
        .string()
        .required(i18next.t("validation_phone_is_required_message"))
        .max(
            MOBILE_LENGTH,
            i18next.t("validation_phone_length_message", { length: MOBILE_LENGTH })
        )
        .matches(MOBILE_REGEX, i18next.t("validation_phone_format_message"))
    // city: Yup
    //     .string()
    //     .required(i18next.t("validation_phone_is_required_message"))
});