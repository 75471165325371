import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { Spinner } from '../../../components/ui';
import { LocalStorageService, MixpanelService, localStorageKeys } from "../../../application/services";
import ExpandMore from '../../../assets/img/arrow_down.svg'
import ExpandMoreBlack from '../../../assets/img/arrow_down_black.svg'
import * as Sentry from '@sentry/react';
import { ServiceProxy } from '../../../ServiceProxy';
import { etisalatHost } from '../../../config/enums';
import { CityName } from './cityName';

export function Governorates(props) {
    const { t } = useTranslation();
    const [governorateValue, setGovernorateValue] = useState(null)
    const [governorates, setGovernorates] = useState([])
    const [loading, setLoading] = useState(true)
    const handleClickNext = () => {
        props.handleClickNext()
    }

    useEffect(() => {
        ServiceProxy.UtilityService.getGovernorates()
            .then(response => {
                if (response.data) {
                    setGovernorates(response.data)
                    setLoading(false)
                    const governorate = LocalStorageService.get(localStorageKeys.governorateID)
                    const value = governorate ? response.data.find(item => item.id === governorate) : null
                    setGovernorateValue(value)
                }
                else {
                    setLoading(false)
                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });

    }, []);// eslint-disable-next-line

    return (
        <div>
            {loading ? <Spinner /> :
                <>
                    <div className="heading mt-45 mb-30">
                        <p>{props.title ? props.title : t("can_you_tell_me")}</p>
                        <h3>{t("governorate_name_label")}</h3>
                    </div>
                    <Autocomplete
                        options={governorates || []}
                        getOptionLabel={(option) => option.name || null}
                        popupIcon={<img src={etisalatHost ? ExpandMoreBlack : ExpandMore} alt="expand" />}

                        onChange={(event, value) => {
                            setGovernorateValue(value)
                            LocalStorageService.set(localStorageKeys.governorateID, value.id);
                            LocalStorageService.set(localStorageKeys.governorate, value.name);
                            MixpanelService.getInstance().selectGovernorate({ governorate: value.name })
                        }}
                        value={governorateValue}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant={etisalatHost ? 'outlined' : 'standard'}
                                placeholder={t("governorate_name_placeholder")}
                                onFocus={() => MixpanelService.getInstance().clickGovernmentField()}
                            />
                        )}
                        classes={{
                            option: etisalatHost ? 'menuItem' : null
                        }}
                    />
                    {governorateValue && <CityName handleClickNext={handleClickNext} />}
                </>
            }
        </div>
    )
}
