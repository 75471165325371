import React from 'react'
import fionkaBlue from '../../../assets/img/fionka_blue.png'
import { EtisalatHost, etisalatHost, freeHomeInsurancePromo, insuranceTypeEnum } from '../../../config/enums'
import { localStorageKeys, LocalStorageService } from '../../../application/services'

export function RadioButton({ label, id, className, checked, value, icon, activeIcon, onChange, onClick, childIcon, child, childActiveIcon, childText }) {
    const isSmallScreen = window.innerWidth <= 400
    return (
        <div className={checked ? "insurance-type-card selected" : "insurance-type-card"} onClick={onClick}
            style={{
                border: (window.location.search.includes(freeHomeInsurancePromo) ||
                    LocalStorageService.get(localStorageKeys.promoCode) === freeHomeInsurancePromo) &&
                    id === insuranceTypeEnum.MotorInsuranceId && "1px solid #0f3eca"
            }}>
            <div className={icon ? 'insuranceTypeIcon' : ''}>
                {icon &&
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: (window.location.search.includes(freeHomeInsurancePromo) ||
                            LocalStorageService.get(localStorageKeys.promoCode) === freeHomeInsurancePromo) &&
                            id === insuranceTypeEnum.MotorInsuranceId && 4
                    }}>
                        <img style={{
                            marginRight: (window.location.search.includes(freeHomeInsurancePromo) ||
                                LocalStorageService.get(localStorageKeys.promoCode) === freeHomeInsurancePromo) &&
                                id === insuranceTypeEnum.MotorInsuranceId && 0,
                            marginLeft: (window.location.search.includes(freeHomeInsurancePromo) ||
                                LocalStorageService.get(localStorageKeys.promoCode) === freeHomeInsurancePromo) &&
                                id === insuranceTypeEnum.MotorInsuranceId && 0
                        }} className='image' src={!checked ? icon : activeIcon} alt="icon" width={29} height={29} />
                        {child &&
                            <div className='childStyle' style={{ gap: isSmallScreen && 5 }}>
                                {/*<span className='plus'>+</span>*/}
                                <img className='m-0' src={!checked ? childIcon : childActiveIcon} alt="icon" width={29} height={29} />
                            </div>
                        }
                    </div>
                }
                {etisalatHost ?
                    <label style={{ gap: isSmallScreen && 0 }} className={child ? 'display-flex' : ''} htmlFor={id}>{child && childText}{!EtisalatHost && <br />}{label}</label> :
                    <label style={{ gap: isSmallScreen && 0 }} className={child ? 'display-flex' : ''} htmlFor={id}>{label}{!EtisalatHost && <br />}{child && childText}</label>
                }
            </div>
            <div>
                <input id={id} className={className} type="radio" name={id} label={id}
                    checked={checked} defaultValue={value} readOnly onChange={onChange} />
            </div>
            {child && <img className='fionkaImg' src={fionkaBlue} alt='offer' />}
        </div>
    )
}
export function CustomRadioButton({ label, id, name, checked, value, onChange, onClick }) {
    return (
        <div className="custom-radio" onClick={onClick} >
            <input id={id} type="radio" name={name}
                checked={checked} defaultValue={value} onChange={onChange} />
            <label htmlFor={id}>{label}</label>
        </div>
    )
}