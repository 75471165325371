import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { ServiceProxy } from "../../../ServiceProxy";
import { PolicyPurchased, PaymentFailed, Spinner } from '../../../components';
import { LocalStorageService, localStorageKeys, cookieStorageKeys, CookieStorageService, MixpanelService } from "../../../application/services";
import { postPayEnum, amenliURLS } from '../../../config/enums'
import { splitParams } from '../../../helpers'
import { useTranslation } from "react-i18next";


export function PostPay(props) {
    const { t } = useTranslation();
    const [paramsDict, setParamsDict] = useState(null)
    const [validation, setValidation] = useState(null)
    const [loading, setLoading] = useState(true)
    const token = LocalStorageService.get(localStorageKeys.purchaseToken)
    const payment_method = LocalStorageService.get(localStorageKeys.paymentMethodValue)
    const payment_prefrence = LocalStorageService.get(localStorageKeys.paymentType)
    const validateHMACFromQE = () => {
        const accessToken = CookieStorageService.getCookie(cookieStorageKeys.token)
        const data = {
            psp_id: LocalStorageService.get(localStorageKeys.paymentMethod),
            recommendation_id: LocalStorageService.get(localStorageKeys.recommendationId),
            query_string: window.location.search.replace("?", "")
        }
        ServiceProxy.PaymentService.validateHMAC(data, accessToken)
            .then(response => {
                if (response && response.data) {
                    setLoading(false)
                    setValidation(response.data)
                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });
    }
    const validateHMACFromPurchaseLink = () => {
        const data = {
            psp_id: LocalStorageService.get(localStorageKeys.paymentMethod),
            purchase_ref: LocalStorageService.get(localStorageKeys.purchaseRef),
            query_string: window.location.search.replace("?", "")
        }
        ServiceProxy.PurchaseService.validateHMAC(data, token)
            .then(response => {
                if (response && response.data) {
                    setLoading(false)
                    setValidation(response.data)
                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });
    }
    useEffect(() => {
        getQueryParams()
        if (token) {
            validateHMACFromPurchaseLink()
        }
        else {
            validateHMACFromQE()
        }

    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    const getQueryParams = () => {
        const paramsDict = splitParams(window.location.search)
        setParamsDict(paramsDict)
        MixpanelService.getInstance().paymentStatus({
            payment_method,
            payment_prefrence,
            payment_status: paramsDict["success"],
            payment_method_id: LocalStorageService.get(localStorageKeys.paymentMethod)
        })
    }
    const handleTryAgain = () => {
        MixpanelService.getInstance().clickPaymentTryAgain({
            payment_method,
            payment_prefrence,
            payment_method_id: LocalStorageService.get(localStorageKeys.paymentMethod)
        })
        if (token) {
            props.history.replace({ pathname: '/purchase/pay', search: `?token=${token}` })
        }
        else {
            props.history.replace({ pathname: '/checkout' })
        }
    }
    const handleReturnAfterSuccess = () => {
        if (token) {
            window.location.href = amenliURLS.Website
        }
        else {
            props.history.replace({ pathname: '/' })
        }
    }
    return (
        <div className="container" id="page-wrapper">
            {loading ? <Spinner /> :
                paramsDict && paramsDict["success"] === postPayEnum.True && validation && validation.status === postPayEnum.Success ?
                    <PolicyPurchased onClick={handleReturnAfterSuccess} btnText={token ? t("return_home") : t("return_home")} /> :
                    <PaymentFailed onClick={handleTryAgain} />
            }
        </div>
    )
}
