
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, DatePickerUI, DateRangePicker } from '../../../components';
import { calculateAge, convertArabic2EnNumbers } from '../../../helpers';
import { LocalStorageService, MixpanelService, localStorageKeys } from "../../../application/services";
import differenceInYears from 'date-fns/differenceInYears'
import { addMonths, format } from 'date-fns';
import { etisalatHost, insuranceTypeEnum } from '../../../config/enums';
import { TextField } from '../../../components/ui';
import { useFormik } from "formik";
import { dobValidationSchema } from '../schema';
import { ServiceProxy } from '../../../ServiceProxy';
import * as Sentry from "@sentry/react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
export default function TravelDate(props) {
    const { t } = useTranslation();
    const [travelDateValue, setTravelDateValue] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);
    const [deptureDate, setDeptureDate] = useState(null)
    const [returnDate, setReturnDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const handleClickNext = () => {
        props.handleClickNext()
        LocalStorageService.set(localStorageKeys.travelDate, deptureDate);
        LocalStorageService.set(localStorageKeys.returnDate, returnDate);
    }
    const handleChangeDate = (item) => {
        setTravelDateValue([item.selection])
        setDeptureDate(item.selection.startDate.toString() !== "Invalid Date" && formatDate(item.selection.startDate))

        setReturnDate(item.selection.endDate.toString() !== "Invalid Date" && formatDate(item.selection.endDate))

    }
    const formatDate = (value) => {
        return format(new Date(value), 'yyyy-MM-dd')
    }
    useEffect(() => {
        if (deptureDate) {
            const max = addMonths(new Date(deptureDate), 24)
            setMaxDate(max)
        }
    }, [deptureDate])
    const minDate = new Date()

    return (
        <div>
            <>
                <div className="heading mt-45 mb-15">
                    <p>{t("can_you_tell_me")}</p>
                    <h3>{t("travel_abroad_label")}</h3>
                </div>
                <div className='date-range'>
                    <DateRangePicker
                        startDatePlaceholder={t("travel_abroad_placeholder")}
                        endDatePlaceholder={t("travel_return_placeholder")}
                        minDate={minDate}
                        maxDate={maxDate ? maxDate : addMonths(new Date(), 24)}
                        ranges={travelDateValue}
                        inputFormat="dd/MM/yyyy"
                        onChange={(item) => {
                            handleChangeDate(item)
                        }}
                    />

                </div>

                <div className="btn-wrapper ">
                    <Button
                        className={`btn ${!deptureDate || !returnDate || deptureDate === returnDate ? "disabled" : "active"}`}
                        title={t("next")}
                        disabled={!deptureDate || !returnDate}
                        type="submit"
                        onClick={handleClickNext}
                    />
                </div>
            </>
        </div>


    )
}


export function DateOfBirth(props) {
    const { t } = useTranslation();
    const incuranceTypeId = LocalStorageService.get(localStorageKeys.insuranceTypeID)
    const [dateOfBirhtValue, setDateOfBirthValue] = useState(LocalStorageService.get(localStorageKeys.dateOfBirth));
    const [invalid, setInvalid] = useState(false)
    const handleClickNext = () => {
        dateOfBirhtValue && props.handleClickNext()
        LocalStorageService.set(localStorageKeys.dateOfBirth, dateOfBirhtValue);
        MixpanelService.getInstance().dateFieldClickNext(incuranceTypeId, { date_of_birth: dateOfBirhtValue })
    }
    const validateDate = (value) => {
        MixpanelService.getInstance().clickDateField(incuranceTypeId)
        if (props.medical && differenceInYears(new Date(), new Date(value)) <= 59) {
            value && setDateOfBirthValue(format(new Date(value), 'yyyy-MM-dd'));
            value && calculateAge(value)
            setInvalid(false)
        }
        else if (props.motor && differenceInYears(new Date(), new Date(value)) >= 18 && differenceInYears(new Date(), new Date(value)) <= 80) {
            value && setDateOfBirthValue(format(new Date(value), 'yyyy-MM-dd'));
            value && calculateAge(value)
            setInvalid(false)
        }
        else if (!props.medical && !props.motor && differenceInYears(new Date(), new Date(value)) <= 80) {
            value && setDateOfBirthValue(format(new Date(value), 'yyyy-MM-dd'));
            value && calculateAge(value)
            setInvalid(false)
        }
        else {
            setInvalid(true)
        }

    }

    return (
        <div >
            <>
                <div className="heading mt-45 mb-15">
                    <p>{props.title ? props.title : t("can_you_tell_me")}</p>
                    <h3>{t("date_of_birth_label")}</h3>
                </div>
                <div>
                    <DatePickerUI label={t("date_of_birth_placeholder")} value={dateOfBirhtValue} data-shrink="false"
                        maxDate={new Date().setHours(0, 0, 0, 0)}
                        openTo="year"
                        views={['year', 'month', 'day']}
                        inputFormat="dd/MM/yyyy"
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleClickNext()
                            }
                        }}
                        onChange={(value) => {
                            validateDate(value)
                        }}
                    />
                    {invalid && <p className='failed-date-of-birth'>{incuranceTypeId === insuranceTypeEnum.MedicalInsuranceId ?
                        t("date_of_birth_error_medical") : incuranceTypeId === insuranceTypeEnum.MotorInsuranceId ? t("date_of_birth_error_motor") : t("date_of_birth_error")}</p>}
                    <div className="btn-wrapper ">
                        <Button
                            className={`btn ${!dateOfBirhtValue || invalid ? "disabled" : "active"}`}
                            title={t("next")}
                            type="submit"
                            onClick={handleClickNext}
                        />
                    </div>
                </div>
            </>
        </div>
    )
}
export function CustomDOB(props) {
    const { t } = useTranslation();
    const incuranceTypeId = LocalStorageService.get(localStorageKeys.insuranceTypeID)
    const [invalid, setInvalid] = useState(false)
    const dateFileds = LocalStorageService.get(localStorageKeys.dateOfBirth) && LocalStorageService.get(localStorageKeys.dateOfBirth).split('-')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const isQuestionExplanation = useFeatureIsOn("question-explanation")
    const handleClickNext = (value) => {
        MixpanelService.getInstance().dateFieldClickNext(incuranceTypeId, { date_of_birth: value })
        props.handleClickNext()
        LocalStorageService.set(localStorageKeys.dateOfBirth, value);
    }
    const formik = useFormik({
        initialValues: {
            day: dateFileds ? dateFileds[2] : '',
            month: dateFileds ? dateFileds[1] : '',
            year: dateFileds ? dateFileds[0] : '',
        },
        validationSchema: dobValidationSchema,
        onSubmit: () => {
            validateDate()
        },
    });
    const validateBirthDate = (values, ageValue) => {
        setLoading(true)
        ServiceProxy.LeadsService.validateBirthDate(values)
            .then((res) => {
                if (res?.data) {
                    setInvalid(false)
                    setLoading(false)
                    calculateAge(ageValue)
                    handleClickNext(values.date_of_birth)
                }
                else if (res?.response?.data?.errors?.date_of_birth?.length > 0) {
                    setMessage(res.response.data.errors.date_of_birth[0])
                    setInvalid(true)
                    setLoading(false)
                }
            })
            .catch(error => {
                if (error?.response?.data?.errors?.date_of_birth?.length > 0) {
                    setMessage(error.response.data.errors.date_of_birth[0])
                    setInvalid(true)
                }
                setLoading(false)
                Sentry.captureException(error);
            })
    }

    const validateDate = () => {
        if (formik.values.day && formik.values.month && formik.values.year) {
            var value = `${Number(convertArabic2EnNumbers(String(formik.values.year)))}/${Number(convertArabic2EnNumbers(String(formik.values.month)))}/${Number(convertArabic2EnNumbers(String(formik.values.day)))}`
            const values = {
                date_of_birth: format(new Date(value), 'yyyy-MM-dd'),
                insurance_type: Number(LocalStorageService.get(localStorageKeys.insuranceTypeID))
            }
            validateBirthDate(values, value)
        }
    }
    return (
        <div>
            <div className="heading mt-45 mb-15">
                <p>{props.title ? props.title : t("can_you_tell_me")}</p>
                <h3>{t("date_of_birth_label")}</h3>
                {isQuestionExplanation && <h4>{t("date_of_birth_explanation")}</h4>}
            </div>
            <form onSubmit={formik.handleSubmit} autoComplete="off" >
                <div className='date-inputs'>
                    <div>
                        <p>{t("day")}</p>
                        <TextField
                            id="day"
                            placeholder='01'
                            controls={false}
                            inputProps={{ maxLength: 2, max: 31, inputMode: 'numeric' }}
                            value={formik.values.day}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.day &&
                                Boolean(formik.errors.day)
                            }
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    const form = event.target.form;
                                    const index = [...form].indexOf(event.target);
                                    etisalatHost ? form[index + 2].focus() : form[index + 1].focus();
                                    event.preventDefault();
                                }
                            }}
                            onKeyUp={(event) => {
                                if (event.target.value.length === 2) {
                                    const form = event.target.form;
                                    const index = [...form].indexOf(event.target);
                                    etisalatHost ? form[index + 2].focus() : form[index + 1].focus();
                                    event.preventDefault();
                                }
                            }}
                            helperText={
                                formik.touched.day &&
                                formik.errors.day
                            }
                            autoFocus={true}
                            name="day"
                            onFocus={() => setInvalid(false)} />
                    </div>
                    <div>
                        <p>{t("month")}</p>
                        <TextField
                            id="month"
                            placeholder='01'
                            controls={false}
                            inputProps={{ maxLength: 2, max: 12, inputMode: 'numeric' }}
                            value={formik.values.month}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.month &&
                                Boolean(formik.errors.month)
                            }
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    const form = event.target.form;
                                    const index = [...form].indexOf(event.target);
                                    etisalatHost ? form[index + 2].focus() : form[index + 1].focus();
                                    event.preventDefault();
                                }
                            }}
                            onKeyUp={(event) => {
                                if (event.target.value.length === 2) {
                                    const form = event.target.form;
                                    const index = [...form].indexOf(event.target);
                                    etisalatHost ? form[index + 2].focus() : form[index + 1].focus();
                                    event.preventDefault();
                                }
                            }}
                            helperText={
                                formik.touched.month &&
                                formik.errors.month
                            }
                            name="month"
                            onFocus={() => setInvalid(false)} />
                    </div>
                    <div>
                        <p>{t("year_label")}</p>
                        <TextField
                            id="year"
                            placeholder='1997'
                            controls={false}
                            inputProps={{ maxLength: 4, max: 2024, inputMode: 'numeric' }}
                            value={formik.values.year}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.year &&
                                Boolean(formik.errors.year)
                            }
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    validateDate()
                                }
                            }}
                            helperText={
                                formik.touched.year &&
                                formik.errors.year
                            }
                            name="year"
                            onFocus={() => setInvalid(false)} />
                    </div>
                </div>
                {invalid && !formik.errors.day && !formik.errors.month && !formik.errors.year ? <p className='failed-date-of-birth'>{message}</p> : null}
                <div className="btn-wrapper ">
                    <Button
                        className={`btn ${!formik.values.day || !formik.values.month || !formik.values.year ? "disabled" : "active"}`}
                        title={t("next")}
                        type="submit"
                        loading={loading}
                    />
                </div>
            </form>
        </div>
    );
};