import React from "react";
 export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    // run logic when catch an error
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{t("error_message")}</h1>;
    }
    return this.props.children;
  }
}

