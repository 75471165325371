import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Spinner } from '../../../components/ui';
import { LocalStorageService, MixpanelService, localStorageKeys } from '../../../application/services';
import { ServiceProxy } from '../../../ServiceProxy';
import * as Sentry from '@sentry/react';
export function EmployeesAgeField(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [ages, setAges] = useState([])
    const [employeesAge, setEmployeesAge] = useState(LocalStorageService.get(localStorageKeys.employeesAge) || '')
    const handleChangeAverageAge = (e) => {
        setEmployeesAge(e.target.value)

    }
    const handleClickNext = () => {
        props.handleClickNext()
        LocalStorageService.set(localStorageKeys.employeesAge, employeesAge.value);
        MixpanelService.getInstance().averageEmployeeAgeClickNext({ average_emloyee: employeesAge.label })
    }
    useEffect(() => {
        ServiceProxy.InsuranceService.getSmeMedicalAges()
            .then(response => {
                if (response && response.data) {
                    setLoading(false)
                    setAges(response.data)
                    const selectedAge = LocalStorageService.get(localStorageKeys.employeesAge)
                    setEmployeesAge(selectedAge ? response.data.find(item => item.value === selectedAge) : '')

                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div>
            {loading ? <Spinner /> :
                <>
                    <div className="heading mt-45 mb-30">
                        <p>{t("can_you_tell_me")} </p>
                        <h3>{t("average_employees_age")}</h3>
                    </div>
                    <Select
                        name="employeesAge"
                        value={employeesAge}
                        placeholder={t("average_employees_age_placeholder")}
                        options={ages}
                        idKey={false}
                        label
                        onChange={handleChangeAverageAge}
                        onOpen={() => MixpanelService.getInstance().clickAverageEmployeeAgeFiled()}
                    />
                    <div className="btn-wrapper">
                        <Button
                            className={`btn ${!employeesAge ? "disabled" : "active"}`}
                            title={t("next")}
                            disabled={!employeesAge}
                            type="submit"
                            onClick={handleClickNext}
                        />
                    </div>
                </>
            }
        </div>
    )
}
