import React from 'react'
import { DateRange } from 'react-date-range';
import { arEG, enUS } from "date-fns/locale";
import { languageEnum } from '../../../config/enums';
import { getCurrentLanguage } from '../../../config/i18n';
export const DateRangePicker = ({ onChange, ranges, startDatePlaceholder, endDatePlaceholder, minDate, maxDate }) => {
  const lang = getCurrentLanguage()

  return (
    <DateRange dragSelectionEnabled={true} startDatePlaceholder={startDatePlaceholder} endDatePlaceholder={endDatePlaceholder}
      locale={lang === languageEnum.Arabic ? arEG : enUS}
      editableDateInputs={false}
      onChange={item => {
        onChange(item)
      }
      }
      moveRangeOnFirstSelection={true}
      ranges={ranges}
      minDate={minDate}
      maxDate={maxDate}
      dateDisplayFormat={"dd/MM/yyyy"}
    />
  )
}

