import Mixpanel from 'mixpanel-browser';
import { TrackingEvents, insuranceTypeEnum, mixpanelProducts } from '../../config/enums';
import { LocalStorageService, localStorageKeys } from './LocalStorageService';
import { ClientJS } from 'clientjs';
import packageJson from '../../../package.json';
export class MixpanelService {
    static _instance;

    static getInstance() {
        if (!MixpanelService._instance) {
            return (MixpanelService._instance = new MixpanelService())
        }
        return MixpanelService._instance;
    }
    constructor() {
        if (MixpanelService._instance) {
            throw new Error('Error: instance creation of MixpanelService is not allowed, Use MixpanelService.getInstance instead.')
        }
        Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", { debug: true, ignore_dnt: true })
    }
    track(name, data) {
        Mixpanel.track(name, data)
    }
    setPeople(data) {
        Mixpanel.people.set(data)
    }
    identifyUser(identifier) {
        Mixpanel.identify(identifier)
    }
    eventProperties(properties) {
        const queryParams = new URLSearchParams(window.location.search);
        return {
            ...properties,
            utm: LocalStorageService.get(localStorageKeys.quotationSource) || queryParams.get('src') || "",
            promo_code: LocalStorageService.get(localStorageKeys.promoCode) || queryParams.get('prm') || "",
            utm_source: LocalStorageService.get(localStorageKeys.campaignUtmSource) || queryParams.get('utm_source') || "organic",
            external_reference: LocalStorageService.get(localStorageKeys.etisalatExternalReference) || queryParams.get('external_reference') || ""
        }
    }
    paymentProperties(properties) {
        const queryParams = new URLSearchParams(window.location.search);
        return {
            ...properties,
            utm: LocalStorageService.get(localStorageKeys.quotationSource) || queryParams.get('src') || "",
            promo_code: LocalStorageService.get(localStorageKeys.promoCode) || queryParams.get('prm') || "",
            plan_name: LocalStorageService.get(localStorageKeys.packageName) || "",
            price: LocalStorageService.get(localStorageKeys.policyPrice) || "",
            utm_source: LocalStorageService.get(localStorageKeys.campaignUtmSource) || queryParams.get('utm_source') || "organic",
            external_reference: LocalStorageService.get(localStorageKeys.etisalatExternalReference) || queryParams.get('external_reference') || ""
        }
    }
    userProperties(properties) {
        const client = new ClientJS();
        const queryParams = new URLSearchParams(window.location.search);
        return {
            ...properties,
            registration_date: new Date(),
            UTM: LocalStorageService.get(localStorageKeys.quotationSource) || queryParams.get('src') || "",
            browser_type: client.getBrowser(),
            browser_version: client.getBrowserVersion(),
            version: packageJson.version,
            os: client.getOS(),
            device_fingerprint: client.getFingerprint(),
            is_mobile_version: client.isMobile(),
            utm_source: LocalStorageService.get(localStorageKeys.campaignUtmSource) || queryParams.get('utm_source') || "organic"
        }
    }
    setMixpanelUserProperties(properties) {
        this.setPeople(this.userProperties(properties))
    }
    pageViewed() {
        this.track(TrackingEvents.QE_VIEWED, this.eventProperties())
    }
    clickReload(properties) {
        this.track(TrackingEvents.CLICK_RELOAD, this.eventProperties(properties))
    }
    changeLanguage(properties) {
        this.track(TrackingEvents.CHANGE_LANGUGAE, this.eventProperties(properties))
        this.setPeople({ web_language: navigator.language || navigator.userLanguage })
    }
    clickAmenliLogo(properties) {
        this.track(TrackingEvents.AMENLI_LOGO_CLICKED, this.eventProperties(properties))
    }
    selectProduct(properties) {
        this.track(TrackingEvents.SELECT_PRODUCT, this.eventProperties(properties))
    }
    clickInsuranceCompany(properties) {
        this.track(TrackingEvents.CLICK_INSURANCE_COMPANY, this.eventProperties(properties))
    }
    qeStepOneViewed(type) {
        switch (type) {
            case insuranceTypeEnum.Medical:
                return this.track(TrackingEvents.QE_MEDICAL_STEP1_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Motor:
                return this.track(TrackingEvents.QE_CAR_STEP1_VIEWED, this.eventProperties());
            case insuranceTypeEnum.InternationalMedical:
                return this.track(TrackingEvents.QE_INTERNATIONAL_MEDICAL_STEP1_VIEWED, this.eventProperties());
            case insuranceTypeEnum.SMEMedical:
                return this.track(TrackingEvents.QE_SME_STEP1_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Home:
                return this.track(TrackingEvents.QE_HOME_STEP1_VIEWED, this.eventProperties());
            default:
                return this.track(TrackingEvents.QE_VIEWED, this.eventProperties());
        }
    }
    qeStepTwoViewed(type) {
        switch (type) {
            case insuranceTypeEnum.Medical:
                return this.track(TrackingEvents.QE_MEDICAL_STEP2_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Motor:
                return this.track(TrackingEvents.QE_CAR_STEP2_VIEWED, this.eventProperties());
            case insuranceTypeEnum.InternationalMedical:
                return this.track(TrackingEvents.QE_INTERNATIONAL_MEDICAL_STEP2_VIEWED, this.eventProperties());
            case insuranceTypeEnum.SMEMedical:
                return this.track(TrackingEvents.QE_SME_STEP2_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Home:
                return this.track(TrackingEvents.QE_HOME_STEP2_VIEWED, this.eventProperties());
            default:
                return this.track(TrackingEvents.QE_VIEWED, this.eventProperties());
        }
    }
    qeStepThreeViewed(type) {
        switch (type) {
            case insuranceTypeEnum.Medical:
                return this.track(TrackingEvents.QE_MEDICAL_STEP3_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Motor:
                return this.track(TrackingEvents.QE_CAR_STEP3_VIEWED, this.eventProperties());
            case insuranceTypeEnum.InternationalMedical:
                return this.track(TrackingEvents.QE_INTERNATIONAL_MEDICAL_STEP3_VIEWED, this.eventProperties());
            case insuranceTypeEnum.SMEMedical:
                return this.track(TrackingEvents.QE_SME_STEP3_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Home:
                return this.track(TrackingEvents.QE_HOME_STEP3_VIEWED, this.eventProperties());
            default:
                return this.track(TrackingEvents.QE_VIEWED, this.eventProperties());
        }
    }
    qeStepFourViewed(type) {
        switch (type) {
            case insuranceTypeEnum.Medical:
                return this.track(TrackingEvents.QE_MEDICAL_STEP4_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Motor:
                return this.track(TrackingEvents.QE_CAR_STEP4_VIEWED, this.eventProperties());
            case insuranceTypeEnum.InternationalMedical:
                return this.track(TrackingEvents.QE_INTERNATIONAL_MEDICAL_STEP4_VIEWED, this.eventProperties());
            case insuranceTypeEnum.SMEMedical:
                return this.track(TrackingEvents.QE_SME_STEP4_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Home:
                return this.track(TrackingEvents.QE_HOME_STEP4_VIEWED, this.eventProperties());
            default:
                return this.track(TrackingEvents.QE_VIEWED, this.eventProperties());
        }
    }
    qeStepFiveViewed(type) {
        switch (type) {
            case insuranceTypeEnum.Motor:
                return this.track(TrackingEvents.QE_CAR_STEP5_VIEWED, this.eventProperties());
            case insuranceTypeEnum.InternationalMedical:
                return this.track(TrackingEvents.QE_INTERNATIONAL_MEDICAL_STEP5_VIEWED, this.eventProperties());
            case insuranceTypeEnum.SMEMedical:
                return this.track(TrackingEvents.QE_SME_STEP5_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Home:
                return this.track(TrackingEvents.QE_HOME_STEP5_VIEWED, this.eventProperties());
            default:
                return this.track(TrackingEvents.QE_VIEWED, this.eventProperties());
        }
    }
    qeStepSixViewed(type) {
        switch (type) {
            case insuranceTypeEnum.Motor:
                return this.track(TrackingEvents.QE_CAR_STEP6_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Home:
                return this.track(TrackingEvents.QE_HOME_STEP6_VIEWED, this.eventProperties());
            default:
                return this.track(TrackingEvents.QE_VIEWED, this.eventProperties());
        }
    }
    qeStepSevenViewed(type) {
        switch (type) {
            case insuranceTypeEnum.Motor:
                return this.track(TrackingEvents.QE_CAR_STEP7_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Home:
                return this.track(TrackingEvents.QE_HOME_STEP7_VIEWED, this.eventProperties());
            default:
                return this.track(TrackingEvents.QE_VIEWED, this.eventProperties());
        }
    }
    qeLoadingPoliciesViewed(type) {
        switch (type) {
            case insuranceTypeEnum.Medical:
                return this.track(TrackingEvents.QE_MEDICAL_STEP5_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Motor:
                return this.track(TrackingEvents.QE_CAR_STEP8_VIEWED, this.eventProperties());
            case insuranceTypeEnum.SMEMedical:
                return this.track(TrackingEvents.QE_SME_STEP6_VIEWED, this.eventProperties());
            case insuranceTypeEnum.InternationalMedical:
                return this.track(TrackingEvents.QE_INTERNATIONAL_MEDICAL_STEP6_VIEWED, this.eventProperties());
            case insuranceTypeEnum.Home:
                return this.track(TrackingEvents.QE_HOME_STEP8_VIEWED, this.eventProperties());
            default:
                return this.track(TrackingEvents.QE_VIEWED, this.eventProperties());
        }
    }
    clickDateField(product) {
        if (mixpanelProducts.includes(product))
            this.track(TrackingEvents.CLICK_DATE_FIELD, this.eventProperties())
    }
    dateFieldClickNext(product, properties) {
        if (mixpanelProducts.includes(product)) {
            this.track(TrackingEvents.DATE_CLICK_NEXT, this.eventProperties(properties))
            this.setPeople(properties)
        }
    }
    clickUserNameField(product) {
        if (mixpanelProducts.includes(product))
            this.track(TrackingEvents.CLICK_USER_NAME_FIELD, this.eventProperties())
    }
    userNameFieldClickNext(product, properties) {
        if (mixpanelProducts.includes(product)) {
            this.track(TrackingEvents.USERNAME_CLICK_NEXT, this.eventProperties(properties))
            this.setPeople(properties)
        }
    }
    clickPhoneNumberField(product) {
        if (mixpanelProducts.includes(product))
            this.track(TrackingEvents.CLICK_PHONE_NUMBER_FIELD, this.eventProperties())
    }
    phoneNumberFieldClickNext(product, properties) {
        if (mixpanelProducts.includes(product)) {
            this.track(TrackingEvents.PHONE_NUMBER_CLICK_NEXT, this.eventProperties(properties))
            this.setPeople(properties)
            this.identifyUser(properties.phone_number)
        }
    }
    selectGender(properties) {
        this.track(TrackingEvents.SELECT_GENDER, this.eventProperties(properties))
        this.setPeople(properties)
    }
    selectCarType(properties) {
        this.track(TrackingEvents.SELECT_CAR_TYPE, this.eventProperties(properties))
    }
    clickCarsField() {
        this.track(TrackingEvents.CLICK_CARS_FIELD, this.eventProperties())
    }
    carsFieldClickNext(properties) {
        this.track(TrackingEvents.CAR_BRAND_CLICK_NEXT_BUTTON, this.eventProperties(properties))
    }
    selectCar(properties) {
        this.track(TrackingEvents.SELECT_CAR, this.eventProperties(properties))
    }
    clickXButton() {
        this.track(TrackingEvents.CLICK_X_BUTTON, this.eventProperties())
    }
    clickCarModelField() {
        this.track(TrackingEvents.CLICK_CARS_MODEL_FIELD, this.eventProperties())
    }
    carModelFieldClickNext(properties) {
        this.track(TrackingEvents.CAR_MODEL_CLICK_NEXT_BUTTON, this.eventProperties(properties))
    }
    selectCarModel(properties) {
        this.track(TrackingEvents.SELECTED_CAR_MODEL, this.eventProperties(properties))
    }
    clickManufactureYearFiled() {
        this.track(TrackingEvents.CLICK_MANUFACTURE_YEAR_FIELD, this.eventProperties())
    }
    carManufactureYearFieldClickNext(properties) {
        this.track(TrackingEvents.CAR_MANUFACTURE_CLICK_NEXT_BUTTON, this.eventProperties(properties))
    }
    selectCarManufactureYear(properties) {
        this.track(TrackingEvents.SELECTED_MANUFACTURE_YEAR, this.eventProperties(properties))
    }
    clickCarPriceField() {
        this.track(TrackingEvents.CLICK_CAR_PRICE_FIELD, this.eventProperties())
    }
    carPriceFieldClickNext(properties) {
        this.track(TrackingEvents.CAR_PRICE_CLICK_NEXT_BUTTON, this.eventProperties(properties))
    }
    clickSeeMore(product) {
        if (mixpanelProducts.includes(product))
            this.track(TrackingEvents.CLICK_SEE_MORE, this.eventProperties())
    }
    clickSeeLess(product) {
        if (mixpanelProducts.includes(product))
            this.track(TrackingEvents.CLICK_SEE_LESS, this.eventProperties())
    }
    loadingPolicies(product) {
        if (mixpanelProducts.includes(product))
            this.track(TrackingEvents.LOADING_POLICIES_RUNNING, this.eventProperties())
    }
    loadingPoliciesStopped(product) {
        if (mixpanelProducts.includes(product))
            this.track(TrackingEvents.LOADING_POLICIES_STOPPED, this.eventProperties())
    }
    clickChoosePolicy(product, properties) {
        if (mixpanelProducts.includes(product))
            this.track(TrackingEvents.CLICK_CHOOSE_POLICY, this.eventProperties(properties))
    }
    clickInfoIcon() {
        this.track(TrackingEvents.CLICK_INFO_ICON, this.eventProperties())
    }
    clickMedicalNetwork(properties) {
        this.track(TrackingEvents.CLICK_MEDICAL_NETWORK, this.eventProperties(properties))
    }
    clickBenefitTable(properties) {
        this.track(TrackingEvents.CLICK_BENEFIT_TABLE, this.eventProperties(properties))
    }
    thankyouViewed() {
        this.track(TrackingEvents.THANKYOU_VIEWED, this.paymentProperties())
    }
    clickPoceedToPayment() {
        this.track(TrackingEvents.CLICK_PROCEED_TO_PAYMENT, this.paymentProperties())
    }
    clickReturnHome() {
        this.track(TrackingEvents.CLICK_RETURN_HOME, this.paymentProperties())
    }
    checkoutViewed() {
        this.track(TrackingEvents.CHECKOUT_VIEWED, this.paymentProperties())
    }
    selectPaymentPrefrence(properties) {
        this.track(TrackingEvents.SELECT_PAYMENT_PREFRENCE, this.paymentProperties(properties))
    }
    selectPaymentMethod(properties) {
        this.track(TrackingEvents.SELECT_PAYMENT_METHOD, this.paymentProperties(properties))
    }
    paymentStatus(properties) {
        this.track(TrackingEvents.PAYMENT_STATUS, this.paymentProperties(properties))
    }
    clickPaymentTryAgain(properties) {
        this.track(TrackingEvents.CLICK_PAYMENT_TRY_AGAIN, this.paymentProperties(properties))
    }
    clickCkeckoutPoceedToPayment(properties) {
        this.track(TrackingEvents.CLICK_CHECKOUT_PROCEED_TO_PAYMENT, this.paymentProperties(properties))
    }
    selectUsaCoverage(properties) {
        this.track(TrackingEvents.SELECT_USA_COVERAGE, this.eventProperties(properties))
    }
    clickRequestCall(properties) {
        this.track(TrackingEvents.REQUEST_CALL, this.eventProperties(properties))
    }
    thankYouRequestCall() {
        this.track(TrackingEvents.THANK_YOU_REQUEST_CALL, this.eventProperties())
    }
    companyNameFieldClickNext(properties) {
        this.track(TrackingEvents.COMPANY_CLICK_NEXT, this.eventProperties(properties))
    }
    totalEmployeeFieldClickNext(properties) {
        this.track(TrackingEvents.TOTAL_EMPLOYEE_CLICK_NEXT, this.eventProperties(properties))
    }
    averageEmployeeAgeClickNext(properties) {
        this.track(TrackingEvents.SELECT_AVERAGE_EMPLOYEE_AGE, this.eventProperties(properties))
    }
    clickAverageEmployeeAgeFiled() {
        this.track(TrackingEvents.CLICK_AVERAGE_EMPLOYEE_AGE_FIELD, this.eventProperties())
    }
    selectTenantType(properties) {
        this.track(TrackingEvents.TENANT_TYPE, this.eventProperties(properties))
    }
    clickGovernmentField() {
        this.track(TrackingEvents.CLICK_GOVERNMENT_FIELD, this.eventProperties())
    }
    selectGovernorate(properties) {
        this.track(TrackingEvents.SELECT_GOVERNORATE, this.eventProperties(properties))
    }
    clickCityField() {
        this.track(TrackingEvents.CLICK_CITY_FIELD, this.eventProperties())
    }
    selectCity(properties) {
        this.track(TrackingEvents.SELECT_CITY, this.eventProperties(properties))
    }
    clickHomeValueField() {
        this.track(TrackingEvents.CLICK_HOME_VALUE_FIELD, this.eventProperties())
    }
    HomeValueFieldClickNext(properties) {
        this.track(TrackingEvents.HOME_VALUE_CLICK_NEXT, this.eventProperties(properties))
    }
    clickWhatsappButton(properties) {
        this.track(TrackingEvents.REQUEST_WHATSAPP, this.eventProperties(properties))
    }
}