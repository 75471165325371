import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button,Select, Spinner } from '../../../components/ui';
import { LookupService, LocalStorageService, localStorageKeys } from '../../../application/services';


export function LifeObjectiveField(props) {
    const { t } = useTranslation();
    const [lifeObjective, setLifeObjective] = useState('')
    const [lifeObjectives, setLifeObjectives] = useState([])
    const [loading, setLoading] = useState(true)
  
    const handleChangeObjective = (e) => {
        setLifeObjective(e.target.value)
    }
    const handleClickNext = () => {
        props.handleClickNext()
        LocalStorageService.set(localStorageKeys.lifeInsuranceGoal, lifeObjective.key);
    }
    const loadLookups = async () => {
        const lookupService = await LookupService.getInstance();
        const lookups = lookupService.lookups;
        return lookups;
    };
    useEffect(() => {
        setTimeout(async () => {
            const lookups = await loadLookups();
            if (lookups && lookups.life) {
                setLifeObjectives(lookups.life)
                const lifeObjectiveValue=LocalStorageService.get(localStorageKeys.lifeInsuranceGoal)
                setLifeObjective(lifeObjectiveValue ? lookups.life.find(item=>item.key===lifeObjectiveValue) : '')
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }, 10);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>{
            loading ? <Spinner /> :
                <>
                    <div className="heading mt-45 mb-30">
                        <p>{t("can_you_tell_me")} </p>
                        <h3>{t("policy_goal_label")}</h3>
                    </div>
                    <Select
                        name="lifeObjective"
                        value={lifeObjective}
                        placeholder={t("policy_goal_placehloder")}
                        options={lifeObjectives && lifeObjectives}
                        onChange={handleChangeObjective}
                        idKey={true}
                       
                    />
                    <div className="btn-wrapper"> 
                        <Button
                            className={`btn ${!lifeObjective  ? "disabled" : "active"}`}
                            title={t("next")}
                            disabled={!lifeObjective}
                            type="submit"
                            onClick={handleClickNext}
                        />
                    </div>
                </>
        }
        </div>
    )
}
