import React, { useState, useEffect, useRef } from 'react'
import * as Sentry from "@sentry/react";
import axios from 'axios';
import { useFormik } from 'formik';
import { Redirect } from 'react-router-dom';
import { PaymentTypes, PaymentMethods, MobileWalletPhoneNumber } from './components';
import { PolicySummaryCard, Spinner, withErrorHandler } from '../../components';
import { paymentTypeEnum, paymentMethodsEnum } from '../../config/enums';
import { ServiceProxy } from "../../ServiceProxy";
import { LocalStorageService, localStorageKeys, cookieStorageKeys, CookieStorageService, MixpanelService } from "../../application/services";
import { convertPriceToCents } from '../../helpers';
import { phoneNumberValidationSchema } from '../quotationEngine/schema';
function Checkout(props) {
    const [paymentType, setPaymentType] = useState(paymentTypeEnum.FullAmount)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentMethodValue, setPaymentMethodValue] = useState('')
    const [paymentMethods, setPaymentMethods] = useState([])
    const [loading, setLoading] = useState(true)
    const [pay, setPay] = useState(false)
    const unmounted = useRef(false);
    const formik = useFormik({
        initialValues: {
            phoneNumber: LocalStorageService.get(localStorageKeys.mobileWalletNumber) || '',
        },
        validationSchema: phoneNumberValidationSchema,
        onSubmit: (values) => {
            LocalStorageService.set(localStorageKeys.mobileWalletNumber, values.phoneNumber);
        },
    });

    const ChangePaymentType = (type) => {
        if (!pay) {
            setPaymentType(type)
            MixpanelService.getInstance().selectPaymentPrefrence({ payment_prefrence: type })
            if (type === paymentTypeEnum.Installments && paymentMethods.instalments.length === 1) {
                ChangePaymentMethod(paymentMethods.instalments[0].id, paymentMethods.instalments[0].value)
            }
            else if (type !== paymentTypeEnum.Installments && paymentMethods.full_payment.length === 1) {
                ChangePaymentMethod(paymentMethods.full_payment[0].id, paymentMethods.full_payment[0].value)
            }
            else {
                setPaymentMethod(null)
                setPaymentMethodValue('')
            }
        }
    }
    const ChangePaymentMethod = (method, value) => {
        if (!pay) {
            setPaymentMethod(method)
            setPaymentMethodValue(value)
            MixpanelService.getInstance().selectPaymentMethod({ payment_method: value, payment_method_id: method })
        }
    }
    useEffect(() => {
        MixpanelService.getInstance().checkoutViewed()
        window.scrollTo(0, 0)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {

        if (validateToken()) {
            const paymentMethodsData = {
                promo_code: LocalStorageService.get(localStorageKeys.promoCode) || null,
                recommendation_id: Number(LocalStorageService.get(localStorageKeys.recommendationId))
            }
            ServiceProxy.PaymentService.getPaymentMethods(paymentMethodsData)
                .then(response => {
                    if (response && response.data) {
                        setPaymentMethods(response.data)
                        if (response.data.full_payment.length === 0 && response.data.installment.length === 0) {
                            props.history.replace({ pathname: `/success`, state: { insurance_type: props.location.state?.insurance_type }, search: props.location.search })
                        }
                        else {
                            if (response.data.full_payment.length === 0 && response.data.instalments.length > 0) {
                                setPaymentType(paymentTypeEnum.Installments)
                                if (response.data.instalments.length === 1) {
                                    ChangePaymentMethod(response.data.instalments[0].id, response.data.instalments[0].value)
                                }
                            }
                            if (response.data.full_payment.length === 1) {
                                ChangePaymentMethod(response.data.full_payment[0].id, response.data.full_payment[0].value)
                            }
                        }
                    }
                })
                .then(() => setLoading(false))
                .catch(function (error) {
                    setLoading(false)
                    Sentry.captureException(error);
                });
        }
        return () => { unmounted.current = true }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    const validateToken = () => {
        return CookieStorageService.getCookie(cookieStorageKeys.token)
    }
    const PayQuotaion = () => {
        if (validateToken()) {
            LocalStorageService.set(localStorageKeys.paymentMethod, paymentMethod)
            LocalStorageService.set(localStorageKeys.paymentMethodValue, paymentMethodValue)
            MixpanelService.getInstance().clickCkeckoutPoceedToPayment({ payment_method: paymentMethodValue, payment_method_id: paymentMethod, payment_prefrence: paymentType })
            formik.handleSubmit()
            const accessToken = CookieStorageService.getCookie(cookieStorageKeys.token)
            const data = {
                psp_id: paymentMethod,
                lead_id: LocalStorageService.get(localStorageKeys.quotationId),
                wallet_phone_number: formik.values.phoneNumber || null,
                amount_cents:
                    (paymentType === paymentTypeEnum.FullAmount) ||
                        (paymentType === paymentTypeEnum.Installments && LocalStorageService.get(localStorageKeys.allowDiscountWithInstalment) === true) ?

                        convertPriceToCents(LocalStorageService.get(localStorageKeys.discountedPolicyPrice)) :
                        convertPriceToCents(LocalStorageService.get(localStorageKeys.policyPrice))
            }
            setPay(true)
            ServiceProxy.LeadsService.payQuotation(data, accessToken)
                .then(response => {
                    if (response && response.data) {
                        LocalStorageService.set(localStorageKeys.paymentType, paymentType)
                        LocalStorageService.set(localStorageKeys.paymentMethod, paymentMethod)

                        const result = response.data;
                        window.scrollTo(0, 0)
                        if (result.iframe_url) {
                            props.history.push({ pathname: '/buy-policy', state: { url: result.iframe_url }, search: props.location.search })
                        }
                        else if (result.redirect_url) {
                            window.location.href = result.redirect_url

                        }
                        else if (result.kiosk_reference) {
                            props.history.push({ pathname: '/kiosk-code', state: { code: result.kiosk_reference }, search: props.location.search })
                        }
                        else {
                            props.history.push({ pathname: '/success', search: props.location.search })
                        }
                    }
                    setPay(false)
                })
                .catch(function (error) {
                    setPay(false)
                    Sentry.captureException(error);
                });
        }
    }
    if (!validateToken()) return <Redirect to={{
        pathname: `/${LocalStorageService.get(localStorageKeys.insuranceType)}/loading-packages`,
        state: {
            insurance_type: LocalStorageService.get(localStorageKeys.insuranceType),
        },
        search: props.location.search
    }} />
    return (
        <div className="container" id="page-wrapper">
            {loading || pay ? <Spinner /> :
                <div className="checkout checkout-wrapper">
                    <div className='flex-box gap-120'>
                        <div className='flex-basis-50 pb-60'>
                            <PaymentTypes value={paymentType} ChangePaymentType={ChangePaymentType} paymentMethods={paymentMethods} />
                            <PaymentMethods
                                data={paymentType === paymentTypeEnum.FullAmount ? paymentMethods.full_payment : paymentMethods.instalments}
                                ChangePaymentMethod={ChangePaymentMethod}
                                paymentMethod={paymentMethod} />
                            {paymentMethodValue === paymentMethodsEnum.Wallet &&
                                <MobileWalletPhoneNumber formik={formik} />
                            }

                        </div>
                        <div className='flex-basis-35'>
                            <PolicySummaryCard
                                installment={paymentType === paymentTypeEnum.Installments}
                                activeBtn={(paymentMethodValue && paymentMethodValue !== paymentMethodsEnum.Wallet)
                                    || (paymentMethodValue && paymentMethodValue === paymentMethodsEnum.Wallet && formik.values.phoneNumber && Boolean(formik.errors.phoneNumber) === false)}
                                PayQuotaion={PayQuotaion}
                                policyPrice={LocalStorageService.get(localStorageKeys.policyPrice)}
                                discountedPolicyPrice={LocalStorageService.get(localStorageKeys.discountedPolicyPrice)}
                                monthlyPolicyPrice={LocalStorageService.get(localStorageKeys.monthlyPolicyPrice)}
                                allowDiscountWithInstalment={LocalStorageService.get(localStorageKeys.allowDiscountWithInstalment)}
                                packageName={LocalStorageService.get(localStorageKeys.packageName)}
                                pay={pay}
                                insuranceCompany={LocalStorageService.get(localStorageKeys.insuranceCompany)} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default withErrorHandler(Checkout, axios)